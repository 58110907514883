import React from 'react';
import { useFreeTrial } from 'hooks/user';
import SubscriptionPlans from './components/SubscriptionPlans';
import FreeToTeamPlan from './components/SubscriptionPlans/components/FreeToTeamPlan';

function SubscriptionPage() {
  // Get the active plan ID
  // List of LTD plans
  const { isPlanExpired, isPlanFree } = useFreeTrial();

  return (
    <div className="bg-primary-foreground overflow-y-visible max-h-[max-content] h-full">
      <div className="p-[32px]">
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[15px] flex justify-center">
          <div className="mr-4">Plans</div>
        </div>
        {isPlanExpired && (
          <div>
            <div className="flex justify-center mb-6">
              <div>Your free trial has expired</div>
            </div>
            <FreeToTeamPlan />
          </div>
        )}
        {/* {isPlanFree && <FreeToTeamPlan />} */}
        {!isPlanExpired && !isPlanFree && <SubscriptionPlans />}
      </div>
    </div>
  );
}

export default SubscriptionPage;
