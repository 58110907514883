/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import { Card } from 'components/ui/card';
import { Slider } from 'components/ui/slider';
import AlertDialog from 'components/AlertDialog';
import Button from 'components/Button';
import { useTotalSignatureWorkspace } from 'hooks/workspace';
import keyBy from 'lodash/keyBy';
import { handleContactUs, predefinedValues } from '../utils';
import { PLAN_STATE } from './SoloPlans';

const handleActivePlan = (subscriptionDetails, plans) => {
  if (!subscriptionDetails?.priceId || !plans.length)
    return { isActive: false, signatureLimit: null };

  const activePlan = plans
    .flatMap((plan) => plan.paddlePrices)
    .find((price) => price.id === subscriptionDetails.priceId);

  if (activePlan) {
    let customData = activePlan.custom_data;

    // Parse the custom data if it's a string
    if (typeof customData === 'string') {
      try {
        customData = JSON.parse(customData);
      } catch (e) {
        customData = {};
      }
    }

    // Update the user count from signature_limit
    const signatureLimit = Number(customData?.signature_limit);
    return { isActive: true, signatureLimit };
  }

  return { isActive: false, signatureLimit: null };
};

const TeamPlans = ({ plans, subscriptionDetails, dialogRef, openCheckout }) => {
  const [userCount, setUserCount] = useState(5);

  const { isActive, signatureLimit } = handleActivePlan(
    subscriptionDetails,
    plans,
  );

  useEffect(() => {
    if (isActive) {
      if (signatureLimit && userCount !== signatureLimit) {
        setUserCount(signatureLimit);
      }
    }
  }, [isActive, signatureLimit]);

  const { totalSignatureUsed } = useTotalSignatureWorkspace();
  const teamPlans = plans.find((plan) => plan.name === 'TEAMS');

  if (!teamPlans) return null;
  // Map signature_limit from custom_data with corresponding priceId
  const priceMapping = keyBy(
    teamPlans.paddlePrices,
    'custom_data.signature_limit',
  );

  const priceData = priceMapping[userCount] || {
    priceId: teamPlans.paddlePrices[0].id,
    custom_data: {
      Per_User: userCount ?? 0,
      signature_limit: userCount ?? 0,
    },
  };
  const totalPrice = priceData?.unit_price?.amount || 0;
  const monthlyPrice = priceData?.custom_data?.Per_User * userCount;
  const yearlyPriceBeforeDiscount = monthlyPrice * 12;
  // const isActive = isActivePlan(priceData.priceId, teamPlans.id);
  const getPlanState = (allPlans, signatureLimit, activePriceId) => {
    if (signatureLimit === 101) return PLAN_STATE.CONTACT;
    if (totalSignatureUsed > signatureLimit) {
      return PLAN_STATE.CONTACT;
    }
    if (!allPlans?.length) return PLAN_STATE.UPDATE;
    if (!activePriceId) return PLAN_STATE.UPDATE;
    const activePlan = allPlans.find((price) => price.id === activePriceId);
    if (['PROFESSIONAL', 'SOLO'].includes(activePlan?.description))
      return PLAN_STATE.UPGRADE;
    const mininumSignatureLimit = Number(
      activePlan?.custom_data?.signature_limit,
    );
    if (mininumSignatureLimit < Number(signatureLimit)) {
      return PLAN_STATE.UPGRADE;
    }
    if (mininumSignatureLimit === Number(signatureLimit)) {
      return PLAN_STATE.ACTIVE;
    }
    if (mininumSignatureLimit > Number(signatureLimit)) {
      return PLAN_STATE.CONTACT;
    }
    return PLAN_STATE.UPDATE;
  };

  const renderPlanButton = ({ price, planId, allPlans }) => {
    const state = getPlanState(
      allPlans,
      Number(price?.custom_data?.signature_limit),
      subscriptionDetails?.priceId,
    );
    if (state === PLAN_STATE.ACTIVE) {
      return (
        <Button
          onClick={() => openCheckout(price.id, planId)}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
          disabled
        >
          Active Plan
        </Button>
      );
    }
    if (state === PLAN_STATE.UPDATE) {
      return (
        <Button
          onClick={() => openCheckout(price.id, planId)}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
        >
          Update Plan
        </Button>
      );
    }
    if (state === PLAN_STATE.UPGRADE) {
      return (
        <Button
          onClick={() => openCheckout(price.id, planId)}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
        >
          Upgrade Plan
        </Button>
      );
    }
    if (state === PLAN_STATE.CONTACT) {
      return (
        <Button
          onClick={handleContactUs}
          className="w-full py-3 bg-primary text-white-0 rounded-xl"
        >
          Contact us
        </Button>
      );
    }
    return (
      <Button
        onClick={handleContactUs}
        className="w-full py-3 bg-primary text-white-0 rounded-xl"
      >
        Contact Us
      </Button>
    );
  };

  const handleOk = () => {
    openCheckout(priceData?.priceId, teamPlans?.id); // Call openCheckout with priceId and planId
    dialogRef.current?.close();
  };

  const handleSliderChange = (value) => {
    setUserCount(value[0]);
  };

  const allPlans = plans.flatMap((plan) => plan.paddlePrices);

  return (
    <>
      <AlertDialog
        ref={dialogRef}
        message="This action will change your current plan"
        handleOk={handleOk}
      />
      <Card bordered className="p-4 shadow-md rounded-xl">
        <div>
          <div className="flex flex-row justify-between items-center mb-2">
            <h1 className="text-2xl font-semibold">TEAMS</h1>
            {userCount !== 101 && (
              <div className="text-lg font-bold">
                ${priceData?.custom_data?.Per_User}/user
              </div>
            )}
          </div>

          <div className="flex flex-col items-center mb-4">
            <span className="font-medium text-lg mb-2">
              {userCount === 101 ? '100+' : `${userCount}`} Signatures
            </span>
            <Slider
              range={false}
              value={[userCount]}
              min={5}
              max={101}
              step={1}
              onValueChange={(value) => {
                const closestValue = predefinedValues.reduce((prev, curr) =>
                  Math.abs(curr - value[0]) < Math.abs(prev - value[0])
                    ? curr
                    : prev,
                );
                handleSliderChange([closestValue]);
              }}
              className="w-full"
            />
          </div>

          {userCount !== 101 && (
            <>
              <div className="text-[20px] leading-none font-bold text-primary-1000 mb-1">
                ${monthlyPrice}{' '}
                <span className="text-[18px] font-normal">/ mo</span>
              </div>
              <div className="text-[13px] text-[#676767] mb-5">
                <span className="line-through">
                  ${yearlyPriceBeforeDiscount.toFixed(2)}
                </span>{' '}
                ${totalPrice / 100} billed yearly (20% off)
              </div>
            </>
          )}

          {renderPlanButton({
            price: priceData,
            planId: teamPlans?.id,
            allPlans,
          })}
          <ul className="mt-4 space-y-2">
            {teamPlans.features.map((feature, idx) => (
              <li key={idx} className="text-black-0">
                {feature.label}
              </li>
            ))}
          </ul>
        </div>
      </Card>
    </>
  );
};

export default TeamPlans;
