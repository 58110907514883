import { getTemplateImages } from 'common/constants';

const Template15 = [
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_15'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Anthony Baker',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'General Manager |',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: "Baker's bistro",
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'a@bakersbistro.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(345) 087-1239',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://www.bakerbistro.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://www.bakerbistro.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      // {
      //   link: 'https://twitter.com/#',
      //   name: 'twitter',
      //   type: 'company',
      //   label: 'TWITTER',
      //   value: 'https://twitter.com/#',
      //   isVariable: false,
      //   utagEnabled: true,
      //   variableValue: null,
      // },
    ],
    information: {
      personalInformation: {
        name: 'Mark deven',
        position: 'CEO',
        department: 'Marketing',
      },
      businessInformation: {
        companyName: '',
        companyAddress: 'Surat, India.',
        companyLogo: null,
        logoWidth: 80,
      },
      contactInformation: {
        email: 'Demo@gmail.com',
        phone: ' 212-232-3233',
        website: 'https://demo.com',
        websiteShortLink: 'https://server.utags.co/roumQFym',
        emailShortLink: 'https://server.utags.co/HHRhmsYJ',
      },
      socialLinks: {
        1: {
          FACEBOOK: 'https://facebook.com/#',
        },
        2: {
          INSTAGRAM: 'https://www.instagram.com/#',
        },
        3: {
          LINKEDIN: 'https://www.linkedin.com/company/#',
        },
        4: {
          TWITTER: 'https://twitter.com/#',
        },
      },
      shortSocialLinks: {
        1: {
          TWITTER: 'https://server.utags.co/pllcJtXw',
        },
        2: {
          LINKEDIN: 'https://server.utags.co/oXoLbNuG',
        },
        4: {
          INSTAGRAM: 'https://server.utags.co/bzesWEGm',
        },
        5: {
          FACEBOOK: 'https://server.utags.co/DzMqqlhV',
        },
      },
      footer: null,
    },
    design: {
      primaryBrandColor: '#F42272',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: [
        {
          key: 'phone',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/phone-icon.png?timestamp=1717386717278',
          width: 16,
          height: 16,
        },
        {
          key: 'email',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/email-icon.png?timestamp=1717386717270',
          width: 16,
          height: 16,
        },
        {
          key: 'website',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/website-icon.png?timestamp=1717386717285',
          width: 16,
          height: 16,
        },
        {
          key: 'address',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/address-icon.png?timestamp=1717386717344',
          width: 16,
          height: 16,
        },
        {
          key: 'facebook',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/facebook-icon.png?timestamp=1717386717371',
          width: 20,
          height: 20,
        },
        {
          key: 'instagram',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/instagram-icon.png?timestamp=1717386717430',
          width: 20,
          height: 20,
        },
        {
          key: 'linkedin',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/linkedin-icon.png?timestamp=1717386717372',
          width: 20,
          height: 20,
        },
        {
          key: 'twitter',
          url: 'https://static.dev.sendsig.com/icons/d89b90a9-5dec-4d07-bfff-2f0382f435d2/twitter-icon.png?timestamp=1717386717304',
          width: 20,
          height: 20,
        },
      ],
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 120,
            borderRadius: 4,
            padding: '12px 6px',
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 14,

            lineHeight: 1.27,
          },
        },
        headerFont: {
          style: {
            fontSize: 13,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 445,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
      },
    },
    cta: {
      title: null,
      url: null,
      shortUrl: null,
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
    headshotUrl: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F42272',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 15',
      image: null,
      key: 'TEMPLATE_15',
      description: null,
      isActive: true,
      config: null,
      templateId: 15,
    },
    name: 'My Signature 15',
  },
];

export default Template15;
