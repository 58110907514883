import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Dialog, DialogContent } from 'components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { Input } from 'components/ui/input';
import { Button } from 'components/ui/button';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useWorkspaceContext } from 'context/WorkspaceContext';
import ImageUploadPreview from 'components/ImageUploadPreview';
import { useGetUserProfileImageUrl } from 'hooks/teammate';
import { Plus } from 'lucide-react';
import { omit } from 'lodash';
import Cropper from 'components/Cropper';
import { toast } from 'hooks/use-toast';

const addTeammateSchema = z.object({
  name: z.string().min(1, { message: 'Name is required' }),
  email: z.string().email(),
  position: z.string().min(1, { message: 'Position is required' }),
  phone: z.string().optional(),
  department: z.string().optional(),
  headshotUrl: z.any().optional(),
});

const AddTeammateDialog = forwardRef(({ onSubmit }, ref) => {
  const { handleProfileImageUpload } = useGetUserProfileImageUrl();

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  }));

  const form = useForm({
    resolver: zodResolver(addTeammateSchema),
    defaultValues: {
      name: '',
      email: '',
      position: '',
      phone: '',
      department: '',
      headshotUrl: null,
    },
  });

  const handleFormSubmit = async (values, dirtyFields) => {
    setLoading(true);
    let url = values?.headshotUrl || '';
    if (values.headshotUrl && dirtyFields.headshotUrl) {
      url = (await handleProfileImageUpload(values.headshotUrl)) || null;
    }
    const newValues = { ...values, profileImage: url };
    const data = await onSubmit(omit(newValues, ['headshotUrl']));
    if (data) {
      toast({
        closeicn: 'success',
        description: 'Added successfully',
      });
      form.reset();
    }
    setLoading(false);
  };

  const { dirtyFields } = form.formState;
  const draggerContent = <Plus />;
  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent hideCloseButton className="rounded-[6px] max-w-md">
        <Form {...form}>
          <h1>Add Teammate</h1>
          <form
            onSubmit={form.handleSubmit((f) =>
              handleFormSubmit(f, dirtyFields),
            )}
            className="space-y-3"
          >
            <FormField
              control={form.control}
              name="headshotUrl"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Profile Image</FormLabel>
                  <FormControl>
                    {field.value ? (
                      <ImageUploadPreview
                        text="Clear"
                        onChange={() => form.setValue('headshotUrl', '')}
                        imageUrl={field?.value?.[0]?.preview}
                        className="w-[98px] h-[98px] rounded-none"
                      />
                    ) : (
                      <Cropper
                        onOk={(file) => {
                          field.onChange([file]);
                        }}
                        cropperProps={{ circularCrop: false }}
                        showAspectbuttons
                        draggerContent={draggerContent}
                        allowGif
                        baseDraggerClassname="rounded-full h-[98px] w-[98px] py-0"
                      />
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Name</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Name" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Email" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="position"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Position</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Position" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Mobile Number</FormLabel>
                  <FormControl>
                    <Input placeholder="Enter Mobile Number" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="department"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Department</FormLabel>
                  <FormControl>
                    <Input placeholder="Department" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="w-full">
              <Button disabled={loading} type="submit" className="w-full">
                Submit
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
});

export default AddTeammateDialog;
