/* eslint-disable import/prefer-default-export */
import Template1 from '../TemplatesData/Template1';
import Template2 from '../TemplatesData/Template2';
import Template3 from '../TemplatesData/Template3';
import Template4 from '../TemplatesData/Template4';
import Template5 from '../TemplatesData/Template5';
import Template6 from '../TemplatesData/Template6';
import Template7 from '../TemplatesData/Template7';
import Template8 from '../TemplatesData/Template8';
import Template9 from '../TemplatesData/Template9';
import Template10 from '../TemplatesData/Template10';
import Template11 from '../TemplatesData/Template11';
import Template12 from '../TemplatesData/Template12';
import Template13 from '../TemplatesData/Template13';
import Template14 from '../TemplatesData/Template14';
import Template15 from '../TemplatesData/Template15';
import Template16 from '../TemplatesData/Template16';
import Template17 from '../TemplatesData/Template17';
import Template18 from '../TemplatesData/Template18';
import Template19 from '../TemplatesData/Template19';
import Template20 from '../TemplatesData/Template20';
import Template21 from '../TemplatesData/Template21';
import Template23 from '../TemplatesData/Template23';
import Template22 from '../TemplatesData/Template22';
import Template24 from '../TemplatesData/Template24';
import Template25 from '../TemplatesData/Template25';
import Template26 from '../TemplatesData/Template26';
import Template27 from '../TemplatesData/Template27';
import Template28 from '../TemplatesData/Template28';
import Template29 from '../TemplatesData/Template29';
import Template30 from '../TemplatesData/Template30';
import Template31 from '../TemplatesData/Template31';
import Template32 from '../TemplatesData/Template32';
import Template33 from '../TemplatesData/Template33';
import Template34 from '../TemplatesData/Template34';
import Template35 from '../TemplatesData/Template35';
import Template36 from '../TemplatesData/Template36';
import Template37 from '../TemplatesData/Template37';
import Template38 from '../TemplatesData/Template38';
import Template39 from '../TemplatesData/Template39';
import Template40 from '../TemplatesData/Template40';

export const TEMPLATE_MODELS = [
  Template1,
  Template2,
  Template3,
  Template4,
  Template5,
  Template6,
  Template7,
  Template8,
  Template9,
  Template10,
  Template11,
  Template12,
  Template13,
  Template14,
  Template15,
  Template16,
  Template17,
  // Template18,
  // Template19,
  Template20,
  Template21,
  Template22,
  Template23,
  Template24,
  Template25,
  Template26,
  Template27,
  // Template28,
  Template30,
  Template31,
  Template32,
  Template33,
  Template34,
].flatMap((d) => d);

export const CATEGORY_MODELS = [
  'All',
  'Minimal',
  'Creative',
  'Formal',
  'Text only',
  'With headshot',
  'Promotional',
].flatMap((d) => d);

export const CATEGORY_MAP_MODELS = {
  1: ['Creative', 'Formal'], // Belongs to both Creative and Formal
  2: ['Creative', 'With headshot'],
  3: ['Creative', 'With headshot'],
  4: ['Formal', 'With headshot'],
  5: ['Formal', 'With headshot'],
  6: ['Formal', 'With headshot'],
  7: ['Formal', 'With headshot'],
  8: ['Formal', 'With headshot'],
  9: ['Formal', 'With headshot'],
  10: ['Creative', 'With headshot'],
  11: ['Text only'],
  12: ['Creative', 'With headshot'],
  13: ['Creative', 'With headshot'],
  14: ['Minimal', 'With headshot'],
  15: ['Minimal', 'With headshot'],
  16: ['Minimal', 'With headshot'],
  17: ['Text only'],
  // 18: ['Creative', 'With headshot'],
  // 19: ['Creative', 'With headshot'],
  20: ['Creative', 'With headshot'],
  21: ['Minimal', 'With headshot'],
  22: ['Promotional', 'With headshot'],
  23: ['Promotional', 'With headshot'],
  24: ['Promotional', 'With headshot'],
  26: ['Promotional', 'With headshot'],
  27: ['Promotional', 'With headshot'],
  // 28: ['Promotional', 'With headshot'],
  30: ['Minimal', 'With headshot'],
  31: ['Minimal', 'With headshot'],
  32: ['Minimal', 'With headshot'],
  33: ['Minimal', 'With headshot'],
  34: ['Minimal', 'With headshot'],
};
