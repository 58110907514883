/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import { useAppContext } from 'AppContext';
import { get } from 'lodash';
import { formatDateTime } from 'common/utils';
import {
  useCancelSubscriptionUrl,
  useGetRecurringPlans,
  useSubscriptionDetails,
} from 'hooks/plans';
import {
  useTotalSignatureWorkspace,
  useFetchWorkspaces,
} from 'hooks/workspace';
import { useGetUser } from 'hooks/user';
import ApplyCoupon from 'modules/Common/CouponAction/ApplyCoupon';
import LoaderComponent from 'components/LoaderComponent';
import Button from 'components/Button';

function BillingPage() {
  const {
    state: { currentUser, subscriptionDetails, recurringPlans },
  } = useAppContext();
  const [loading, setLoading] = useState(false);

  const [getSubscriptionDetails] = useSubscriptionDetails();
  const [getRecurringPlans] = useGetRecurringPlans();
  const allPaddlePrices = recurringPlans?.flatMap((plan) => plan.paddlePrices);

  const activePlan = allPaddlePrices?.find(
    (price) => price.id === subscriptionDetails?.priceId,
  );
  const signatureLimit = get(currentUser, 'signatureLimit', null);
  const [getUser] = useGetUser();

  const [getCancelSubscriptionUrl, { error }] = useCancelSubscriptionUrl();

  const getBillingDetails = () => {
    // Check if data and data.getSubscriptionDetails exist before destructuring
    if (!subscriptionDetails) return null;

    const { billingPeriod, nextBilledAt } = subscriptionDetails;

    return {
      billingPeriod,
      nextBilledAt,
    };
  };
  const billingDetails = getBillingDetails();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const { totalSignatureUsed } = useTotalSignatureWorkspace();

  useEffect(() => {
    if (currentUser?.id) {
      getSubscriptionDetails({
        variables: {
          where: { userId: currentUser?.id },
        },
      });
    }
  }, [currentUser?.id]);

  const fetchData = async () => {
    setLoading(true);
    await getRecurringPlans();
    await fetchWorkspaces();
    await getUser();
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleCancelSubscription = async () => {
    if (currentUser?.id) {
      try {
        // Trigger the lazy query to get the cancel subscription URL
        const result = await getCancelSubscriptionUrl();

        // Extract the URL from the result data and redirect the user
        const cancelUrl = result?.data?.getCancelSubscriptionUrl?.url;

        if (cancelUrl) {
          window.open(cancelUrl, '_blank'); // Open the cancel URL in a new tab
        }
      } catch (err) {
        // console.error('Error fetching cancel subscription URL', err);
      }
    }
  };

  if (loading) {
    return (
      <LoaderComponent
        circleClassName="mt-20 w-7 h-7 text-gray-500"
        setWidth="100%"
        classNames="w-full h-full relative"
      />
    );
  }

  return (
    <div className="bg-primary-foreground h-[calc(100vh_-_71px)] flex justify-center items-start">
      <div className="p-[32px] w-full max-w-xl">
        {/* Billing Header */}
        <div className="text-primary-1000 text-[40px] leading-[48px] font-[700] mb-[40px] flex justify-center">
          <div className="mr-4">Billing</div>
        </div>

        {/* Active Plan Block */}
        {currentUser?.activePlan?.name && (
          <div className="bg-white-0 shadow-lg p-6 rounded-md flex justify-between items-center mb-8">
            <div className="flex flex-col items-center">
              <div className="text-h6 text-primary">Active Plan</div>
              <div className="text-regular-l">
                {[6]?.includes(currentUser?.planId)
                  ? activePlan?.description
                  : currentUser?.activePlan?.name?.split('_')?.join(' ')}
              </div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-h6 text-primary">Signature Limit</div>
              <div className="text-regular-l">{signatureLimit}</div>
            </div>
            <div className="flex flex-col items-center">
              <div className="text-h6 text-primary">Signature Used</div>
              <div className="text-regular-l">{totalSignatureUsed}</div>
            </div>
          </div>
        )}

        {/* Subscription Details Block */}
        <div className="m-16">
          <h2 className="text-xl font-semibold text-primary text-center mb-6">
            Subscription Details
          </h2>
          {billingDetails ? (
            <div className="bg-white-0 py-6 px-10 rounded-md shadow-md flex justify-between items-center">
              <p className="text-primary flex flex-col items-center">
                <strong>Start Date</strong>
                <p className="text-black-0 p-0">
                  {formatDateTime(billingDetails.billingPeriod?.starts_at)}
                </p>
              </p>
              <p className="text-primary flex flex-col items-center">
                <strong>End Date</strong>
                <p className="text-black-0 p-0">
                  {formatDateTime(billingDetails.nextBilledAt)}
                </p>
              </p>
            </div>
          ) : (
            <div className="bg-white-0 p-6 rounded-md shadow-md text-center">
              <p className="text-primary">No subscription details available.</p>
            </div>
          )}
        </div>

        {/* <div className="flex justify-center items-center text-primary-1000 m-16">
          {isCouponAvailable && isFreeUser && <ApplyCoupon />}
        </div> */}

        {/* Cancel Subscription Button */}
        {/* Uncomment if you want to add this later */}
        {/* <div className="flex justify-center mt-8">
          {currentUser?.activePlan?.name && (
            <Button
              onClick={handleCancelSubscription}
              className="bg-red-600 text-white py-2 px-4 rounded-md"
              disabled={loading}
            >
              {loading ? 'Loading...' : 'Cancel Subscription'}
            </Button>
          )}
        </div> */}
      </div>
    </div>
  );
}

export default BillingPage;
