/* eslint-disable no-undef */

import { PlusSquareFilled } from '@ant-design/icons';
import { useAppContext } from 'AppContext';
import { Popover, PopoverContent, PopoverTrigger } from 'components/ui/popover';
import { Slider } from 'components/ui/slider';
import { SOCIAL_LINKS, IMAGE_TYPE } from 'common/constants';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { capitalize, get, cloneDeep, set } from 'lodash';
import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useMemo,
} from 'react';
import { Textarea } from 'components/ui/textarea';
import { Button } from 'components/ui/button';
import { socialImages } from 'assets/social-images';
import BaseDragger from 'components/BaseDragger';
import { GET_SIGN_URL } from 'modules/Signature/graphql/Queries';
import { useLazyQuery } from '@apollo/client';
import { useSignatureId } from 'hooks/signature';
import { fileUpload, resizeImage, URL_REGEX } from 'common/utils';
import ImageUploadPreview from 'components/ImageUploadPreview';
import CustomEditor from 'components/CustomEditor';
import { ClearableInput } from 'components/Inputs';
import { Plus, Loader2, SquareMinus } from 'lucide-react';
import { useGetUserProfileImageUrl } from 'hooks/teammate';
import Cropper from 'components/Cropper';
import JoditEditor from 'jodit-react';

const InformationControl = forwardRef((props, ref) => {
  const {
    state: { signature },
    setSignature,
  } = useAppContext();

  const { signatureId } = useSignatureId();
  const { handleProfileImageUpload } = useGetUserProfileImageUrl();

  const [socialPopover, setSocialPopover] = useState(false);
  const [fields, setFields] = useState([...SOCIAL_LINKS]);
  const [loading, setLoading] = useState(false);

  const onSubmit = (data) => {};

  const headshotFields = signature?.fields?.filter((field) =>
    ['headshotUrl'].includes(field.name),
  );
  const personalFields = signature?.fields?.filter((field) =>
    ['name', 'position', 'department'].includes(field.name),
  );

  const businessFields = signature?.fields?.filter((field) =>
    ['companyName', 'companyAddress'].includes(field.name),
  );

  const companyLogoFields = signature?.fields?.filter((field) =>
    ['companyLogo'].includes(field.name),
  );

  const contanctFields = signature?.fields?.filter((field) =>
    ['email', 'phone', 'website'].includes(field.name),
  );

  const footerFields = signature?.fields?.filter((field) =>
    ['footer'].includes(field.name),
  );
  const socialFields = signature?.fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );
  const addSocialFieldsName = socialFields?.map((f) => f?.name);

  const urlValidation = z.string().refine(
    (val) => {
      const urlRegex = URL_REGEX;
      return urlRegex.test(val);
    },
    {
      message: 'URL must start with https://  .',
    },
  );

  const headshotFieldsSchema = headshotFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );
  const personalFieldsSchema = personalFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const businessFieldsSchema = businessFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const companyLogoFieldsSchema = companyLogoFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const contanctFieldsSchema = contanctFields?.reduce((acc, field) => {
    if (field.name === 'website') {
      return {
        ...acc,
        [field.name]:
          field.value !== null
            ? urlValidation.optional().or(z.literal(''))
            : z.string().nullable(),
      };
    }
    return {
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    };
  }, {});

  const socialFieldsSchema = socialFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? urlValidation.optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const footerFieldsSchema = footerFields?.reduce(
    (acc, field) => ({
      ...acc,
      [field.name]:
        field.value !== null
          ? z.string().optional().or(z.literal(''))
          : z.string().nullable(),
    }),
    {},
  );

  const schema = z.object({
    ...(headshotFieldsSchema ?? {}),
    ...(personalFieldsSchema ?? {}),
    ...(businessFieldsSchema ?? {}),
    ...(companyLogoFieldsSchema ?? {}),
    ...(contanctFieldsSchema ?? {}),
    ...(socialFieldsSchema ?? {}),
    ...(footerFieldsSchema ?? {}),
  });

  const form = useForm({
    resolver: zodResolver(schema),
    values: {
      ...signature?.fields?.reduce((acc, field) => {
        acc[field.name] = field.value || '';
        return acc;
      }, {}),
    },
  });

  useImperativeHandle(ref, () => ({
    form,
  }));

  useEffect(() => {
    setFields(fields?.filter((f) => !addSocialFieldsName?.includes(f?.name)));
  }, []);

  const watch = form.watch();

  const handleFormChange = () => {
    const { profileImage, ...values } = form.getValues();
    const updatedFields = [...(signature?.fields ?? [])].map((field) => ({
      ...field,
      value: values[field.name] ?? field?.value,
    }));
    const updatedValues = {
      ...signature,
      headshotUrl: profileImage,
      fields: updatedFields,
    };
    setSignature(updatedValues);
  };

  const handleRemoveSocialLink = (name) => {
    setSignature({
      ...signature,
      fields: signature.fields.filter((field) => field.name !== name),
    });
    setFields([...fields, SOCIAL_LINKS.find((link) => link.name === name)]);
  };

  const renderProfileImage = (field) => {
    if (loading) {
      return (
        <div className="flex justify-center items-center w-[98px] h-[98px] rounded-full">
          <Loader2 className="animate-spin mr-2 h-5 w-5" />
        </div>
      );
    }
    return (
      <ImageUploadPreview
        text="Clear"
        onChange={() => {
          form.setValue(field.name, '');
        }}
        file
        imageUrl={watch.headshotUrl}
        className="w-[98px] h-[98px] rounded-none"
      />
    );
  };

  useEffect(() => {
    const subscription = form.watch(() => handleFormChange());
    return () => subscription.unsubscribe();
  }, [form, signature]);

  const [getSignUrl] = useLazyQuery(GET_SIGN_URL, {
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError: () => {},
  });

  const generateSignUrl = async (variables) => {
    const res = await getSignUrl({
      variables: {
        data: {
          ...variables,
        },
      },
    });
    return res?.data?.getCompanyLogoUploadSignedUrl;
  };

  // const handleFileUpload = async (files, field) => {
  //   const file = await resizeImage(files?.[0], 200);
  //   if (file) {
  //     const { name } = file || {};
  //     const ext = name.substring(name.lastIndexOf('.') + 1);
  //     const filename = name.split('.').slice(0, -1).join('.');
  //     const newFilename = `${filename}.${ext}`;
  //     const res = await generateSignUrl({
  //       fileName: newFilename,
  //       signatureId,
  //     });
  //     if (res) {
  //       const { signedUrl, key } = res;
  //       const outputLogoImage = `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
  //       try {
  //         await fileUpload(signedUrl, file);
  //         form.setValue(field.name, outputLogoImage);
  //       } catch (error) {
  //         return false;
  //       }
  //     }
  //   }
  // };

  const handleFileUpload = async (files, field) => {
    const file = files?.[0];
    if (file) {
      const isGif = file.type === IMAGE_TYPE.gif;
      let uploadFile = file;
      if (!isGif) {
        uploadFile = await resizeImage(file, 200);
      }
      const { name } = uploadFile || {};
      const ext = name.substring(name.lastIndexOf('.') + 1);
      const filename = name.split('.').slice(0, -1).join('.');
      const newFilename = `${filename}.${ext}`;
      const res = await generateSignUrl({
        fileName: newFilename,
        signatureId,
      });
      if (res) {
        const { signedUrl, key } = res;
        const outputFileUrl = `${process.env.REACT_APP_SOCIAL_ICONS_URL}/${key}`;
        try {
          await fileUpload(signedUrl, uploadFile);
          form.setValue(field.name, outputFileUrl);
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('File upload failed', error);
          return false;
        }
      }
    }
  };

  const handleSocialSelect = (data) => {
    const selectedItem = {
      name: data.name,
      isVariable: false,
      label: capitalize(data.name),
      link: '',
      shortLink: '',
      type: '',
      utagEnabled: true,
      value: '',
      variableValue: null,
    };

    setSignature({
      ...signature,
      fields: [...signature?.fields, selectedItem],
    });
    setFields(fields?.filter((f) => f.name !== selectedItem?.name));
    setSocialPopover(false);
  };

  const logoWidth = get(signature, 'design.styles.companyLogo.style.width', 80);
  const profilepictureWidth = get(
    signature,
    'design.styles.headshot.style.width',
    98,
  );
  const profilepictureradius = get(
    signature,
    'design.styles.headshot.style.borderRadius',
    5,
  );

  const handleProfileImageChange = async (file, field) => {
    if (file) {
      setLoading(true);
      field.onChange(file);
      const url = (await handleProfileImageUpload(file)) || null;
      form.setValue(field.name, url);
      setLoading(false);
    }
  };

  const draggerContent = <Plus />;

  const config = useMemo(
    () => ({
      toolbar: false,
      readonly: false,
      showPlaceholder: true,
      buttons: [],
      statusbar: false,
      removeButtons: ['about', 'source', 'iframe'],
      maxHeight: 'auto',
      height: 'auto',
      style: {
        minHeight: '50px',
      },
    }),
    [],
  );

  return (
    <>
      <div>
        <header className="px-4 pb-4 h-[102px] flex items-center">
          <h1 className="font-primary text-h6 font-bold text-neutral-1000">
            Information
          </h1>
        </header>
        <div className="pl-4 pr-4">
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
              {headshotFields?.map((field) => (
                <FormField
                  key={field.name}
                  control={form.control}
                  name={field.name}
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Profile Image</FormLabel>
                      <FormControl>
                        {field.value ? (
                          renderProfileImage(field)
                        ) : (
                          <Cropper
                            onOk={(file) =>
                              handleProfileImageChange([file], field)
                            }
                            showAspectbuttons
                            cropperProps={{ circularCrop: false }}
                            draggerContent={draggerContent}
                            allowGif
                            baseDraggerClassname="rounded-full h-[98px] w-[98px] py-0"
                          />
                        )}
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              ))}
              <div className="mb-4 mt-2">
                {watch.headshotUrl && (
                  <div>
                    <FormLabel className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Profile picture width</span>
                      <span>{profilepictureWidth}px</span>
                    </FormLabel>
                    <Slider
                      range
                      value={[profilepictureWidth]}
                      min={30}
                      max={200}
                      onValueChange={([value]) => {
                        const signatureClone = cloneDeep(signature);
                        set(
                          signatureClone,
                          'design.styles.headshot.style.width',
                          value,
                        );
                        setSignature(signatureClone);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="mb-4 mt-2">
                {watch.headshotUrl && (
                  <div>
                    <FormLabel className="mb-2 flex justify-between text-medium-base font-primary">
                      <span>Profile picture radius</span>
                      <span>{profilepictureradius}px</span>
                    </FormLabel>
                    <Slider
                      range
                      value={[profilepictureradius]}
                      min={0}
                      max={30}
                      onValueChange={([value]) => {
                        const signatureClone = cloneDeep(signature);
                        set(
                          signatureClone,
                          'design.styles.headshot.style.borderRadius',
                          value,
                        );
                        setSignature(signatureClone);
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="my-4">
                <div className="mb-2 block text-medium-base font-primary bg-primary-foreground">
                  Personal Information
                </div>

                {personalFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          <ClearableInput
                            className="max-w-full mb-4"
                            placeholder={field.label}
                            {...formField}
                            type={field.type}
                            allowClear
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </div>

              <div>
                <div className="mb-2  block text-medium-base font-primary bg-primary-foreground">
                  Business Information
                </div>
                {businessFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          <ClearableInput
                            className="max-w-full mb-4"
                            placeholder={field.label}
                            {...formField}
                            type={field.type}
                            allowClear
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </div>
              <div>
                <div className="mb-2 block text-medium-base font-primary bg-primary-foreground">
                  Company logo (optional)
                </div>
                {companyLogoFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          {watch.companyLogo ? (
                            <ImageUploadPreview
                              onChange={() => {
                                form.setValue(field.name, '');
                              }}
                              imageUrl={watch.companyLogo}
                            />
                          ) : (
                            <BaseDragger
                              handleChange={(files) =>
                                handleFileUpload(files, formField)
                              }
                              allowGif
                              className="max-w-full mb-2 h-[100px] p-0"
                            >
                              Drag & drop or click to upload company logo
                            </BaseDragger>
                          )}
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
                <div className="mb-4 mt-2">
                  {watch.companyLogo && (
                    <div>
                      <FormLabel className="mb-2 flex justify-between text-medium-base font-primary">
                        <span>Logo width</span>
                        <span>{logoWidth}px</span>
                      </FormLabel>
                      <Slider
                        range
                        value={[logoWidth]}
                        min={50}
                        max={200}
                        onValueChange={([value]) => {
                          const signatureClone = cloneDeep(signature);
                          set(
                            signatureClone,
                            'design.styles.companyLogo.style.width',
                            value,
                          );
                          setSignature(signatureClone);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="mt-8">
                <div className="mb-2  block text-medium-base font-primary bg-primary-foreground">
                  Contact Information
                </div>
                {contanctFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem className="mb-4">
                        <FormControl>
                          <ClearableInput
                            className="max-w-full"
                            placeholder={field.label}
                            {...formField}
                            type={field.type}
                            allowClear
                            onChange={(e) => {
                              formField.onChange(e);
                              form.trigger(field.name);
                            }}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </div>

              <div className="mb-4 mt-6">
                <div className="mb-2 block text-medium-base font-primary bg-primary-foreground">
                  Social links
                </div>
                {socialFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem className="mb-4">
                        <div className="flex items-center space-x-2">
                          <div className="align-baseline">
                            <Button
                              type="button"
                              onClick={() => handleRemoveSocialLink(field.name)}
                              variant="outlineshortwithoutbackground"
                              size="iconchange"
                            >
                              <SquareMinus className="h-5 w-5 text-white" />
                            </Button>
                          </div>
                          <FormControl>
                            <ClearableInput
                              className="max-w-full"
                              placeholder={field.label}
                              {...formField}
                              type={field.type}
                              allowClear
                              onChange={(e) => {
                                formField.onChange(e);
                                form.trigger(field.name);
                              }}
                            />
                          </FormControl>
                        </div>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
                <Popover open={socialPopover} onOpenChange={setSocialPopover}>
                  <PopoverTrigger asChild>
                    <Button className="flex justify-center items-center gap-2">
                      Add <PlusSquareFilled />{' '}
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent>
                    <h3>Select an Option</h3>
                    <div className="grid grid-cols-2 gap-3">
                      {fields?.map((option) => (
                        <div
                          key={option.key}
                          onClick={() => handleSocialSelect(option)}
                          className="flex items-center space-x-2 cursor-pointer"
                        >
                          <div className="h-6 w-6 aspect-square rounded-full overflow-hidden">
                            <img
                              src={socialImages?.[option.key]}
                              style={{
                                display: 'flex',
                                background: 'black',
                                height: '100%',
                                width: '100%',
                              }}
                              width="100%"
                              alt="tik-tok-icon"
                            />
                          </div>
                          <div>{capitalize(option.name)}</div>
                        </div>
                      ))}
                    </div>
                  </PopoverContent>
                </Popover>
              </div>

              <div className="mb-4 mt-6 w-full">
                <div className="mb-2 block text-medium-base font-primary bg-primary-foreground">
                  Footer
                </div>
                {footerFields?.map((field) => (
                  <FormField
                    key={field.name}
                    control={form.control}
                    name={field.name}
                    render={({ field: formField }) => (
                      <FormItem>
                        <FormControl>
                          <CustomEditor
                            ref={formField.ref}
                            value={formField.value}
                            onBlur={formField.onBlur}
                            onChange={(newContent) =>
                              formField.onChange(newContent)
                            }
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                ))}
              </div>
            </form>
          </Form>
        </div>
      </div>
    </>
  );
});

export default InformationControl;
