import React from 'react';
import get from 'lodash/get'; // Assuming you're using lodash's get function

const Divider = ({ style = {}, signature = {}, design = {} }) => {
  const width = get(design, 'styles.separator.style.width', 1);
  return (
    <td aria-label="divider">
      <hr
        style={{
          marginTop: 0,
          paddingBottom: 8,
          borderLeft: 'none',
          borderRight: 'none',
          borderTop: 'none',
          borderBottom: `${width}px solid ${signature?.design?.primaryBrandColor}`,
          ...style,
        }}
      />
    </td>
  );
};

export default Divider;
