import { useGetRoutes } from 'helpers/useGetRoutes';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { workspaceRoutes } from 'routes';
import { useGetGroup } from 'hooks/group';
import { useFetchWorkspaces, useWorkspaceId } from 'hooks/workspace';
import { useGetAllRoles } from 'hooks/role';
import { useFetchWorkspaceTemplates } from 'hooks/workspace-template';
import { useGetWorkspaceTeammateByWorkspaceId } from 'hooks/workspace-teammate';
import { validate as isValidUuid } from 'uuid';
import LoaderComponent from 'components/LoaderComponent';
import { useWindowSize } from 'helpers/useWindowSize';
import MobileOnlyWarning from 'components/MobileOnlyWarning';
import {
  useGetValidAccessToken,
  useWorkspaceConnectionStatus,
} from 'hooks/google-marketplace';
import { useFreeTrial, useGetWorkspaceOwner } from 'hooks/user';
import SubscriptionModal from 'components/SubscriptionModal';
import Header from './Header';
import SideBar from './SideBar';

const WorkspaceLayout = () => {
  const { getRoutes } = useGetRoutes();
  const [fetchWorkspaces] = useFetchWorkspaces();
  const [getWorkspaceOwner] = useGetWorkspaceOwner(); // Use the custom hook here
  const [getGroups] = useGetGroup();
  const [getAllRoles] = useGetAllRoles();
  const { isPlanExpired, redirectToPlan } = useFreeTrial();

  const { workspaceId } = useWorkspaceId();
  const [getWorkspaceTeammateByWorkspaceId] =
    useGetWorkspaceTeammateByWorkspaceId();
  const [fetchWorkspaceTemplates] = useFetchWorkspaceTemplates();
  const [getWorkspaceConnectionStatus] = useWorkspaceConnectionStatus();
  const [loading, setLoading] = useState(true);
  const { size } = useWindowSize();

  const fetchWorkspaceTeammates = async () => {
    await getWorkspaceTeammateByWorkspaceId({
      variables: {
        where: {
          workspaceId,
        },
      },
    });
  };

  const getDefaultPath = () => {
    if (isValidUuid(workspaceId)) {
      return `/app/workspace/${workspaceId}/assets`;
    }
    return null; // or handle the invalid case as needed
  };

  const defaultPath = getDefaultPath();

  const fetchWorkspaceInfo = async () => {
    setLoading(true);
    await fetchWorkspaces();
    await getAllRoles();

    if (workspaceId) {
      await getGroups({
        variables: { workspaceId },
      });
      await fetchWorkspaceTemplates({
        variables: { data: { workspaceId } },
      });
      await fetchWorkspaceTeammates();
      await getWorkspaceOwner({
        variables: {
          where: {
            id: workspaceId,
          },
        },
      }); // Fetch the owner here
      await getWorkspaceConnectionStatus({
        variables: {
          where: {
            workspaceId,
          },
        },
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (isPlanExpired) redirectToPlan();
  }, [isPlanExpired]);

  useEffect(() => {
    fetchWorkspaceInfo();
  }, [workspaceId]);

  const wrapperClass = 'grid grid-cols-[227px_1fr]';

  if (loading) {
    return <LoaderComponent />;
  }
  if (['xs', 'sm', 'md'].includes(size)) {
    return <MobileOnlyWarning />;
  }
  return (
    <div>
      <SubscriptionModal />
      <div>
        <Header />
        <div className={wrapperClass}>
          <SideBar />
          <div className="h-[calc(100vh_-_71px)]">
            <Routes>
              {getRoutes(workspaceRoutes)}
              <Route
                path="*"
                element={<Navigate to={defaultPath || '/app/'} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkspaceLayout;
