/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_WORKSPACES = gql`
  query GetWorkspaces {
    getWorkspaces {
      data {
        id
        name
        signatureLimit
        signatureUsed
        workspaceDomain
      }
    }
  }
`;


export const GET_WORKSPACE_BY_ID = gql`
  query GetWorkspaceById($where: GetWorkspaceByIdInput!) {
    getWorkspaceById(where: $where) {
      data {
        id
        name
        ownerId
        signatureLimit
        signatureUsed
        workspaceDomain
      }
    }
  }
`;
