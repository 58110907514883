import { getTemplateImages } from 'common/constants';

const Template24 = [
  {
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_24'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Jamie Rey',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Head of Marketing',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Indispensable Inc.',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },

      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'jr@indispensablecorp.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '(345) 087-1239',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://yourwebsite.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://indispensablecorp.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: 'https://facebook.com/#',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: getTemplateImages('ctabanner_TEMPLATE_24'),
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      // {
      //   link: 'https://twitter.com/#',
      //   name: 'twitter',
      //   type: 'company',
      //   label: 'TWITTER',
      //   value: 'https://twitter.com/#',
      //   isVariable: false,
      //   utagEnabled: true,
      //   variableValue: null,
      // },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    information: {
      personalInformation: {
        name: 'Mark deven',
        position: 'CEO',
        department: 'Marketing',
      },
      businessInformation: {
        companyName: '',
        companyAddress: 'Surat, India.',
        companyLogo: null,
        logoWidth: 80,
      },
      contactInformation: {
        email: 'Demo@gmail.com',
        phone: ' 212-232-3233',
        website: 'https://demo.com',
        websiteShortLink: 'https://server.utags.co/roumQFym',
        emailShortLink: 'https://server.utags.co/HHRhmsYJ',
      },
      socialLinks: {
        1: {
          FACEBOOK: 'https://facebook.com/#',
        },
        2: {
          INSTAGRAM: 'https://www.instagram.com/#',
        },
        3: {
          LINKEDIN: 'https://www.linkedin.com/company/#',
        },
        4: {
          TWITTER: 'https://twitter.com/#',
        },
      },
      shortSocialLinks: {
        1: {
          TWITTER: 'https://server.utags.co/pllcJtXw',
        },
        2: {
          LINKEDIN: 'https://server.utags.co/oXoLbNuG',
        },
        4: {
          INSTAGRAM: 'https://server.utags.co/bzesWEGm',
        },
        5: {
          FACEBOOK: 'https://server.utags.co/DzMqqlhV',
        },
      },
      footer: null,
    },
    design: {
      primaryBrandColor: '#E98931',
      font: 'Verdana, Geneva, sans-serif',
      theme: 'LIGHT',
      icons: [
        {
          key: 'phone',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/phone-icon-8665b748-ff39-4a5f-83cb-c71cd867ade0.png?timestamp=1728378649244',
          width: 16,
          height: 16,
        },
        {
          key: 'email',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/email-icon-b69906cb-d30e-4f62-9085-bd7334e1c7e4.png?timestamp=1728378649316',
          width: 16,
          height: 16,
        },
        {
          key: 'website',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/website-icon-aab9cbe1-2402-4cf6-92d0-835a71649a79.png?timestamp=1728378649288',
          width: 16,
          height: 16,
        },
        {
          key: 'address',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/address-icon-41e2f673-726b-44b1-9a6e-1c0cbae8989a.png?timestamp=1728378697186',
          width: 16,
          height: 16,
        },
        {
          key: 'facebook',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/facebook-icon-ada222bf-dc96-4a88-94eb-750cf8c2cb65.png?timestamp=1728378697182',
          width: 20,
          height: 20,
        },
        {
          key: 'instagram',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/instagram-icon-d2b473c5-4773-4844-8bde-f03cbb9c7f35.png?timestamp=1728378697260',
          width: 20,
          height: 20,
        },
        {
          key: 'linkedin',
          url: 'https://static.dev.sendsig.com/icons/ab70e794-4481-4af0-96d7-6504d03350c8/linkedin-icon-dd0f4e4e-219a-4d7c-ac31-a59ca8dcbd4e.png?timestamp=1728378697265',
          width: 20,
          height: 20,
        },
      ],
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
            padding: '12px 6px',
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 13,
            lineHeight: 1.32,
          },
        },
        headerFont: {
          style: {
            fontSize: 20,
          },
        },
        icon: {
          type: 'word',
          style: {
            size: 16,
          },
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 20,
          },
        },
        companyLogo: {
          style: {
            width: 120,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 130,
          },
        },
        signoff: {
          style: {
            fontSize: 20,
          },
        },
        separator: {
          style: {
            width: 1,
          },
        },
        socialBanners: {
          style: {
            width: 100,
          },
        },
      },
    },
    cta: {
      title: null,
      url: null,
      shortUrl: null,
    },
    templateId: 'b7573beb-e57c-404a-b487-150fc06fc97b',
    headshotId: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
    headshotUrl: null,
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#FFC322',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'b7573beb-e57c-404a-b487-150fc06fc97b',
      name: 'template 24',
      image: null,
      key: 'TEMPLATE_24',
      description: null,
      isActive: true,
      config: null,
      templateId: 24,
    },
    name: 'My Signature 7',
  },
];

export default Template24;
