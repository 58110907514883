/* eslint-disable no-console */
import React, { useEffect, useRef, memo } from 'react';
import { toPng } from 'html-to-image';
import { useAppContext } from 'AppContext';

function convertToKeyValuePairs(items = []) {
  return items.reduce((accumulator, currentItem) => {
    const { key, ...rest } = currentItem;
    accumulator[key] = rest;
    return accumulator;
  }, {});
}
const WithImageConversion = memo(
  ({ children, fileName = '', signature = {}, showAwsIcons = false }) => {
    const { setBaseImages } = useAppContext();

    const ref = useRef(null);
    const icons = convertToKeyValuePairs(signature?.design?.icons || []);

    const convertToImage = async () => {
      try {
        if (!ref.current) return;
        const height = ref.current.clientHeight;
        const width = ref.current.clientWidth;
        const base = await toPng(ref.current, {
          quality: 1,
          pixelRatio: 1,
          height,
          width,
          canvasHeight: height * 2,
          canvasWidth: width * 2,
          cacheBust: true,
          includeQueryParams: true,
          fontEmbedCSS: false,
          imagePlaceholder: 'icon',
        });
        setBaseImages({ key: fileName, url: base, height, width });
      } catch (error) {
        console.log('error', error);
      }
    };

    useEffect(() => {
      if (showAwsIcons) return;
      convertToImage();
    }, [signature]);

    // console.log('icons', showAwsIcons);

    return showAwsIcons && icons[fileName]?.url ? (
      <img
        src={icons[fileName]?.url}
        alt={fileName}
        height={icons[fileName]?.height}
        width={icons[fileName]?.width}
      />
    ) : (
      <div
        id={fileName}
        style={{ display: 'inline-block' }}
        key={JSON.stringify(signature.id + fileName)}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);

export default WithImageConversion;
