/* eslint-disable arrow-body-style */
import React, { useRef } from 'react';
import {
  ChartBarIcon,
  FourSquareIcon,
  MagicWandIcon,
  NoteBookIcon,
  UserCircleIcon,
} from 'assets/svg';
import { useAppContext } from 'AppContext';
import WorkspaceSwitcher from 'components/WorkspaceSwitcher';
import { useRouter } from 'hooks/router';
import { useTheme } from 'hooks/theme';
import { useIsUserBrandOwner } from 'hooks/brand';
import { useUserPermission } from 'hooks/user';
import { workspaceRoutes } from 'routes';
import { FEATURE_KEYS } from 'common/constants';
import { useGetRoutes } from 'helpers/useGetRoutes';

const itemStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
};

const EXCLUDED_PATHS = [
  '/subscriptions',
  '/profile',
  '/groups/:groupId',
  '/teammates/:workspaceTeammateId',
  '/restrictedPage',
];

const getIconForPath = (path) => {
  switch (path) {
    case '/assets':
      return UserCircleIcon;
    case '/templates':
      return FourSquareIcon;
    case '/groups':
      return ChartBarIcon;
    case '/teammates':
      return NoteBookIcon;
    case '/analytics':
      return ChartBarIcon;
    case '/integrations':
      return MagicWandIcon;
    default:
      return UserCircleIcon;
  }
};

const SideBar = () => {
  const {
    location: { pathname },
    navigate,
    params,
  } = useRouter();
  const {
    state: { currentUser },
  } = useAppContext();

  const { color, foregroundColor } = useTheme();
  const { getRoutesList } = useGetRoutes();
  const workspaceId = params?.workspaceId;
  const { isUserBrandOwner } = useIsUserBrandOwner();
  const { isFeaturesAllowed } = useUserPermission();
  const originalPathRef = useRef(null);

  const items = getRoutesList(workspaceRoutes);
  const filteredItems = items.filter((r) => !EXCLUDED_PATHS.includes(r.path));

  const tabs = filteredItems.map((route) => {
    const { path, name, title } = route;
    return {
      key: name.toLowerCase(),
      path,
      label: title,
      icon: getIconForPath(path),
    };
  });

  const restrictedPaths = [
    '/templates',
    '/groups',
    '/teammates',
    '/analytics',
    '/integrations',
  ];

  const isActiveTab = (path) => {
    if (pathname.includes('/restrictedPage') && originalPathRef.current) {
      return originalPathRef.current === path;
    }
    return pathname.includes(path);
  };

  const handleMenuClick = (path) => {
    if (
      !isFeaturesAllowed(FEATURE_KEYS.TEAM_FEATURE) &&
      restrictedPaths.includes(path)
    ) {
      if (isUserBrandOwner(currentUser?.id, workspaceId)) {
        originalPathRef.current = path;
        navigate(`/app/workspace/${workspaceId}/restrictedPage`);
      } else {
        navigate(`/app/workspace/${workspaceId}${path}`);
      }
    } else {
      navigate(`/app/workspace/${workspaceId}${path}`);
    }
  };

  return (
    <div
      className="h-[calc(100vh_-_71px)] flex justify-between flex-col"
      collapsible
      theme="light"
      trigger={null}
      width={277}
    >
      <div>
        {tabs.map(({ icon: Icon, path, label }) => {
          const isActive = isActiveTab(path);

          return (
            <div
              className="h-[70px]"
              style={{
                ...itemStyle,
                ...(isActive
                  ? {
                      backgroundColor: foregroundColor,
                      borderLeft: `4px solid ${color}`,
                    }
                  : {}),
              }}
              key={path}
              onClick={() => handleMenuClick(path)}
            >
              <div
                className="flex items-center w-full "
                style={{ marginLeft: isActive ? '30px' : '35px' }}
              >
                <Icon
                  fill={isActive ? color : '#7a7289'}
                  className="h-[30px] w-[30px] mr-3"
                />
                <div
                  className="text-[18px]"
                  style={{ color: isActive ? color : '#7a7289' }}
                >
                  {label}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      <div className="px-[10px] py-[14px]">
        <WorkspaceSwitcher />
      </div>
    </div>
  );
};

export default SideBar;
