import { getTemplateImages } from 'common/constants';

const Template13 = [
  {
    id: 'ef33f8a8-485b-4307-9184-563815d28181',
    fields: [
      {
        link: '',
        name: 'headshotUrl',
        type: 'employee',
        label: 'Headshot URL',
        value: getTemplateImages('headshot_TEMPLATE_8'),
        shortLink: '',
        isVariable: true,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'name',
        type: 'employee',
        label: 'Name',
        value: 'Sarah Collins',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'position',
        type: 'employee',
        label: 'Position',
        value: 'Senior Financial Consultant',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'department',
        type: 'employee',
        label: 'Department',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyName',
        type: 'company',
        label: 'Company Name',
        value: 'Collins Advisory',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyAddress',
        type: 'company',
        label: 'Company Address',
        value: '789 Fin, Chicago, IL 60603',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'companyLogo',
        type: 'company',
        label: 'Company Logo',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'footer',
        type: 'company',
        label: 'Footer',
        value:
          'The contents of this email and any attachments are confidential. They are intended for the named recipient(s) only. If you have received this email by mistake, please notify the sender immediately and do not disclose the contents to anyone or make copies thereof”',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: '',
        name: 'email',
        type: 'employee',
        label: 'Email',
        value: 'sarah@collinsadvisory.com',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'phone',
        type: 'employee',
        label: 'Phone',
        value: '+1 (555) 654-7891',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: 'https://yourwebsite.com',
        name: 'website',
        type: 'company',
        label: 'Website',
        value: 'https://www.collinsadvisory.com',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: '',
        name: 'ctaTitle',
        type: 'company',
        label: 'CTA Title',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: false,
        variableValue: null,
      },
      {
        link: null,
        name: 'ctaUrl',
        type: 'company',
        label: 'CTA URL',
        value: '',
        shortLink: null,
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        name: 'ctaBannerImg',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'CTA Banner',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        link: '',
        name: 'ctaBannerUrl',
        type: 'company',
        label: 'CTA Banner Url',
        value: '',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://facebook.com/#',
        name: 'facebook',
        type: 'company',
        label: 'FACEBOOK',
        value: 'https://facebook.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.instagram.com/#',
        name: 'instagram',
        type: 'company',
        label: 'INSTAGRAM',
        value: 'https://www.instagram.com/#',
        shortLink: '',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      {
        link: 'https://www.linkedin.com/company/#',
        name: 'linkedin',
        type: 'company',
        label: 'LINKEDIN',
        value: 'https://www.linkedin.com/company/#',
        isVariable: false,
        utagEnabled: true,
        variableValue: null,
      },
      // {
      //   link: 'https://twitter.com/#',
      //   name: 'twitter',
      //   type: 'company',
      //   label: 'TWITTER',
      //   value: 'https://twitter.com/#',
      //   isVariable: false,
      //   utagEnabled: true,
      //   variableValue: null,
      // },
      {
        name: 'socialBanners',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Social Banners',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyLogoLink',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Logo Link',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyPhone',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Phone',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'companyEmail',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Company Email',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'signOff',
        value: '',
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'SignOff',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
      {
        name: 'customFields',
        value: [],
        type: 'company',
        isVariable: false,
        variableValue: null,
        label: 'Custom Fields',
        utagEnabled: false,
        link: '',
        shortLink: '',
      },
    ],
    design: {
      primaryBrandColor: '#F95402',
      font: null,
      theme: 'LIGHT',
      icons: [
        {
          key: 'phone',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/phone-icon.png?timestamp=1717134686621',
          width: 16,
          height: 16,
        },
        {
          key: 'website',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/website-icon.png?timestamp=1717134686626',
          width: 16,
          height: 16,
        },
        {
          key: 'address',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/address-icon.png?timestamp=1717134686611',
          width: 16,
          height: 16,
        },
        {
          key: 'facebook',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/facebook-icon.png?timestamp=1717134686637',
          width: 20,
          height: 20,
        },
        {
          key: 'instagram',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/instagram-icon.png?timestamp=1717134686608',
          width: 20,
          height: 20,
        },
        {
          key: 'linkedin',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/linkedin-icon.png?timestamp=1717134686658',
          width: 20,
          height: 20,
        },
        {
          key: 'twitter',
          url: 'https://static.dev.sendsig.com/icons/ef33f8a8-485b-4307-9184-563815d28181/twitter-icon.png?timestamp=1717134686609',
          width: 20,
          height: 20,
        },
      ],
      styles: {
        cta: {
          style: {
            type: 'colored',
            width: 100,
            borderRadius: 4,
          },
        },
        font: {
          style: {
            color: '#000000',
            fontSize: 15,
            lineHeight: 1.27,
          },
        },
        headerFont: {
          style: {
            fontSize: 13,
          },
        },
        icon: {
          type: 'rounded',
          style: {},
        },
        socialIcon: {
          type: 'rounded',
          style: {
            size: 22,
          },
        },
        companyLogo: {
          style: {
            width: 200,
          },
        },
        ctaBanner: {
          style: {
            width: 400,
          },
        },
        headshot: {
          style: {
            width: 98,
          },
        },
      },
    },
    cta: {
      title: null,
      url: null,
      shortUrl: null,
    },
    templateId: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
    headshotId: null,
    headshotUrl: getTemplateImages('headshot_TEMPLATE_8'),
    headshot: {
      id: 'ef8bec2e-8e4c-47bf-a3ee-3c9c056dc607',
      image: null,
      config: {
        output: null,
        grayScale: null,
        'background-color': '#F95402',
        processedImageKey: 'headshots/process-headshot-1716783207928.png',
        height: '130px',
        width: '130px',
      },
      styleId: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
      style: {
        id: 'fce9737a-380f-4cef-b7e5-de44ed6a85c4',
        name: 'style 1',
        image: null,
        key: 'STYLE_1',
        description: null,
        isActive: true,
      },
    },
    template: {
      id: 'cb90666e-2151-4f16-99b4-51c9dead8a0b',
      name: 'template 8',
      image: null,
      key: 'TEMPLATE_8',
      description: null,
      isActive: true,
      config: null,
      templateId: 8,
    },
    name: 'My Signature 0',
  },
];

export default Template13;
