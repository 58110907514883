import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';

function TemplateStyleThree({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section2 =
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const allUrlsAreNull =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const ctadivider =
    !getFieldData(fields, 'ctaUrl') &&
    !getFieldData(fields, 'ctaTitle') &&
    !getFieldData(fields, 'ctaBannerImg') &&
    !getFieldData(fields, 'ctaBannerUrl') &&
    !getFieldData(fields, 'footer');

  const separatorWidth = get(design, 'styles.separator.style.width', 1);
  return (
    <table
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      {getFieldData(fields, 'signOff') && (
        <tr>
          {SignOff({
            signature,
            data: getFieldData(fields, 'signOff'),
            style: {
              fontWeight: 700,
              fontFamily: 'inherit',
              paddingBottom: '2px',
            },
          })}
        </tr>
      )}
      <tr style={{}}>
        <td>
          <table
            cellPadding="0"
            cellSpacing="0"
            border="0"
            role="presentation"
            style={{
              borderCollapse: 'collapse !important',
              fontSize: 'inherit',
              paddingBottom: '16px',
            }}
          >
            <tr>
              <td>
                <table
                  cellPadding="0px"
                  ref={mainContentRef}
                  style={{
                    fontSize: 'inherit',
                    paddingBottom: '8px',
                  }}
                >
                  {getFieldData(fields, 'headshotUrl') && (
                    <tr>
                      {HeadshotImage({
                        data: getFieldData(fields, 'headshotUrl'),
                        design,
                        align: 'left',
                        style: {
                          paddingBottom: '8px',
                          textAlign: 'left',
                        },
                      })}
                    </tr>
                  )}

                  {!section2 && (
                    <tr>
                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            fontSize: 'inherit',
                            paddingBottom: '6px',
                          }}
                        >
                          {getFieldData(fields, 'name') && (
                            <tr>
                              {Name({
                                signature,
                                data: getFieldData(fields, 'name'),
                                style: {
                                  color: design?.primaryBrandColor,
                                  fontWeight: '700',
                                  paddingBottom: '2px',
                                },
                              })}
                            </tr>
                          )}
                          {(getFieldData(fields, 'position') ||
                            getFieldData(fields, 'department')) && (
                            <tr>
                              {getFieldData(fields, 'position') &&
                                Position({
                                  signature,
                                  data: getFieldData(fields, 'position'),
                                  style: {
                                    paddingRight: '16px',
                                    paddingBottom: '2px',
                                  },
                                })}
                              {getFieldData(fields, 'department') &&
                                Department({
                                  signature,
                                  data: getFieldData(fields, 'department'),
                                  style: {
                                    paddingBottom: '2px',
                                  },
                                })}
                            </tr>
                          )}

                          {getFieldData(fields, 'companyName') && (
                            <tr>
                              {CompanyName({
                                signature,
                                data: getFieldData(fields, 'companyName'),
                                style: {
                                  fontFamily: 'inherit',
                                  paddingBottom: '2px',
                                },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  )}
                  {!allUrlsAreNull && (
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          borderTop: !allUrlsAreNull
                            ? `${separatorWidth}px solid ${design?.primaryBrandColor}`
                            : '',
                          paddingBottom: '8px',
                        }}
                      />
                    </tr>
                  )}
                  {!allUrlsAreNull && (
                    <tr>
                      <td>
                        <table
                          cellPadding="0px"
                          cellSpacing="0"
                          border="0"
                          role="presentation"
                          style={{
                            fontSize: 'inherit',
                            paddingBottom: '2px',
                          }}
                        >
                          {getFieldData(fields, 'phone') && (
                            <tr>
                              {Phone({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'phone'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'email') && (
                            <tr>
                              {Email({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'email'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'website') && (
                            <tr>
                              {Website({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'website'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyAddress') && (
                            <tr>
                              {CompanyAddress({
                                signature,
                                showAwsIcons,
                                design,
                                data: getFieldData(fields, 'companyAddress'),
                                style: {
                                  fontFamily: 'inherit',
                                },
                              })}
                            </tr>
                          )}
                          {!!socialFields?.length && (
                            <tr>
                              {SocialLinks({
                                signature,
                                showAwsIcons,
                                data: socialFields,
                                design,
                                style: {
                                  paddingBottom: '6px',
                                },
                              })}
                            </tr>
                          )}
                          {getFieldData(fields, 'companyLogo') && (
                            <tr>
                              {CompanyLogo({
                                data: getFieldData(fields, 'companyLogo'),
                                design,
                                style: { paddingBottom: '6px' },
                              })}
                            </tr>
                          )}
                        </table>
                      </td>
                    </tr>
                  )}
                  {!allUrlsAreNull && (
                    <tr>
                      <td
                        colSpan={2}
                        style={{
                          borderTop: !ctadivider
                            ? `${separatorWidth}px solid ${design?.primaryBrandColor}`
                            : '',
                          paddingBottom: '8px',
                        }}
                      />
                    </tr>
                  )}
                </table>
              </td>
            </tr>
            {getFieldData(fields, 'ctaUrl') &&
              getFieldData(fields, 'ctaTitle') && (
                <tr>
                  {CallToAction({
                    signature,
                    showAwsIcons,
                    design,
                    data: {
                      ctaUrl: getFieldData(fields, 'ctaUrl'),
                      ctaTitle: getFieldData(fields, 'ctaTitle'),
                    },
                    style: {
                      paddingBottom: '16px',
                    },
                  })}
                </tr>
              )}
            {getFieldData(fields, 'ctaBannerImg') &&
              getFieldData(fields, 'ctaBannerUrl') && (
                <tr>
                  {CallToActionBanner({
                    data: {
                      ctaBannerUrl: getFieldData(fields, 'ctaBannerUrl'),
                      ctaBannerImg: getFieldData(fields, 'ctaBannerImg'),
                    },
                    design,
                    style: { textAlign: 'left', paddingBottom: '16px' },
                  })}
                </tr>
              )}
            {!!getFieldData(fields, 'socialBanners')?.value?.length && (
              <tr>
                {SocialBanners({
                  data: getFieldData(fields, 'socialBanners'),
                  design,
                  style: {
                    textAlign: 'left',
                    width: '500px',
                  },
                })}
              </tr>
            )}
            {getFieldData(fields, 'footer') && (
              <tr>
                {Footer({
                  data: getFieldData(fields, 'footer'),
                  design,
                  style: {
                    textAlign: 'left',
                    width: '300px',
                  },
                })}
              </tr>
            )}
          </table>
        </td>
      </tr>
      <Branding hideBranding={hideBranding} />
    </table>
  );
}

export default TemplateStyleThree;
