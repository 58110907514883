import { useAppContext } from 'AppContext';
import { EllipsisHorizontalIcon } from 'assets/svg';
import SignatureTemplate from 'components/SignatureTemplate';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'components/ui/dropdown-menu';
import { cn } from 'lib/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useGetRoleForWorkspace, useUserPermission } from 'hooks/user';
import { FEATURE_KEYS, ROLES } from 'common/constants';

const SignatureCard = ({
  signature = {},
  actions = {},
  count = 1,
  signatureCardClassname,
  signatureTemplateClassname,
  handleSignatureCardOnClick,
  hideAction,
  size = 'md', // sm,lg
}) => {
  const contentRef = useRef();
  const [transformStyle, setTransformStyle] = useState({});
  const { setSignature, setHeadshot, handleSubscriptionModal } =
    useAppContext();
  const {
    onClick = () => {},
    handleEdit = () => {},
    handleDelete = () => {},
    handleDuplicate = () => {},
  } = actions;
  const { isFreeUser } = useUserPermission();
  const { isFeaturesAllowed } = useUserPermission();
  const { role } = useGetRoleForWorkspace();

  const items = [
    {
      onClick: () => handleEdit(signature),
      label: <span>Edit</span>,
      key: '0',
      hide: false,
    },
    {
      onClick: () => {
        if (isFreeUser) {
          handleSubscriptionModal(true);
          return; // Exit if the user needs to manage subscriptions
        }
        handleDuplicate(signature);
      },
      label: <span>Duplicate</span>,
      key: '1',
      hide: false,
    },
    {
      onClick: () => handleDelete(signature),
      label: <span>Delete</span>,
      key: '3',
      hide: count === 1,
    },
  ];

  const handleOnClick = (signature) => {
    if (!handleSignatureCardOnClick) {
      setSignature(null);
      setHeadshot(null);
      onClick(signature?.id);
    } else {
      handleSignatureCardOnClick(signature);
    }
  };

  const hideBranding =
    isFeaturesAllowed(FEATURE_KEYS.NO_BRANDING) ||
    role === ROLES.TEAMMATE ||
    role === ROLES.MANAGER;

  const templateNumber = signature?.template?.templateId;

  const DIMENSION_MAP = {
    sm: {
      width: '200px',
      height: '130px',
      scale: 0.4,
    },
    md: {
      width: '350px',
      height: '220px',
      scale: 0.7,
    },
  };

  useEffect(() => {
    if (contentRef.current) {
      const { width, height } = contentRef.current.getBoundingClientRect();
      const selectedSize = DIMENSION_MAP[size] || DIMENSION_MAP.md;
      const scaleFactor = selectedSize.scale;
      const DIMENSION_FACTOR_MAP = {
        sm: 1.2,
        md: 0.2,
      };

      const widthFactor = DIMENSION_FACTOR_MAP[size] || DIMENSION_FACTOR_MAP.md;
      const heightFactor =
        DIMENSION_FACTOR_MAP[size] || DIMENSION_FACTOR_MAP.md;

      // Adjust the transform based on dynamic width and height
      setTransformStyle({
        transform: `scale(${scaleFactor}) translate(-${width * scaleFactor * widthFactor}px, -${height * scaleFactor * heightFactor}px)`,
        pointerEvents: 'none',
      });
    }
  }, [size, contentRef]);

  const dynamicDimensions = DIMENSION_MAP[size] || DIMENSION_MAP.md;

  return (
    <div
      style={{
        width: dynamicDimensions.width,
        minWidth: dynamicDimensions.width,
        maxWidth: dynamicDimensions.width,
        height: dynamicDimensions.height,
      }}
      onClick={() => handleOnClick(signature)}
      className={cn(
        `bg-white-0 relative flex justify-start
        rounded-[8px] overflow-hidden shadow-lg cursor-pointer m-2
        bg-white border-[1px] border-solid border-secondary-400 hover:border-solid hover:border-[1px] hover:border-primary hover:shadow-signature`,
        signatureCardClassname,
      )}
    >
      <div className="w-full relative">
        <div
          id={templateNumber}
          style={transformStyle}
          className={cn(signatureTemplateClassname)}
        >
          <div
            ref={contentRef}
            style={{
              height: dynamicDimensions.height,
              width: dynamicDimensions.width,
            }}
          >
            {signature && (
              <SignatureTemplate
                signature={signature}
                showAwsIcons
                hideBranding={hideBranding}
              />
            )}
          </div>
        </div>
      </div>

      <div
        onClick={(e) => e.stopPropagation()}
        className="absolute bottom-0 w-full box-border flex justify-between items-center px-3 py-2 bg-primary-foreground"
      >
        <div>{signature?.name}</div>
        {!hideAction && (
          <div>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <EllipsisHorizontalIcon />
              </DropdownMenuTrigger>
              <DropdownMenuContent className=" bg-white-0 border-solid border-1 border-gray-400 ">
                {items.map(({ label, key, onClick, hide = false }) => {
                  if (hide) {
                    return null;
                  }
                  return (
                    <DropdownMenuItem
                      key={key}
                      value={key}
                      onClick={onClick}
                      className="w-[auto]"
                    >
                      {label}
                    </DropdownMenuItem>
                  );
                })}
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignatureCard;
