/* eslint-disable arrow-body-style */
import React from 'react';
import {
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from 'components/ui/dialog';
import { Button } from 'components/ui/button';
import { toast } from 'hooks/use-toast';
import { useRemoveTeammateFromGroup } from 'hooks/workspace-teammate';

const RemoveTeammateFromGroup = ({ data, onClose, loading }) => {
  const [removeTeammateFromGroup, { loading: removing }] =
    useRemoveTeammateFromGroup();

  const onDeleteSubmit = async () => {
    try {
      const res = await removeTeammateFromGroup({
        variables: {
          data: {
            ids: [data?.id],
            groupId: data?.groupId,
          },
        },
      });

      if (!res) {
        return toast({
          closeicn: 'destructive',
          description: 'Unable to remove Teammate',
        });
      }

      onClose();
    } catch (error) {
      toast({
        closeicn: 'destructive',
        description: 'An error occurred while removing the teammate',
      });
    }
  };

  return (
    <DialogContent
      className="sm:max-w-[425px] rounded-[6px] max-w-sm"
      hideCloseButton
    >
      <div>
        <DialogHeader className="text-left">
          <DialogTitle className="text-lg font-bold text-gray-900 my-0">
            Remove Teammate from Group
          </DialogTitle>
          <DialogDescription className="text-sm text-gray-500">
            Removing this teammate will also delete their associated signature.
            Are you sure you want to proceed?
          </DialogDescription>
        </DialogHeader>
        <div className="flex w-full">
          <div className="flex justify-end gap-3 w-1/2 ml-auto">
            <Button onClick={() => onClose()} variant="outline">
              Cancel
            </Button>
            <Button
              disabled={loading || removing}
              type="submit"
              onClick={() => onDeleteSubmit()}
            >
              Remove
            </Button>
          </div>
        </div>
      </div>
    </DialogContent>
  );
};

export default RemoveTeammateFromGroup;
