/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from 'react';
import { Tabs, TabsList, TabsTrigger, TabsContent } from 'components/ui/tabs';
import { useAppContext } from 'AppContext';
import {
  useGetPaddleCheckoutTransactionId,
  useGetRecurringPlans,
  useSubscriptionDetails,
  useUpdatePaddleSubscriptionPlan,
} from 'hooks/plans';
import LoaderComponent from 'components/LoaderComponent';
import Button from 'components/Button';
import { cn } from 'lib/utils';
import { useWorkspaceId } from 'hooks/workspace';
import { handleContactUs, useInitializePaddleClient } from './utils';
import SoloPlans from './components/SoloPlans';
import TeamPlans from './components/TeamPlans';

const handleActivePlan = (subscriptionDetails, plans) => {
  if (!subscriptionDetails?.priceId || !plans.length)
    return { isActive: false, signatureLimit: null };

  const activePlan = plans
    .flatMap((plan) => plan.paddlePrices)
    .find((price) => price.id === subscriptionDetails.priceId);

  if (activePlan) {
    let customData = activePlan.custom_data;

    // Parse the custom data if it's a string
    if (typeof customData === 'string') {
      try {
        customData = JSON.parse(customData);
      } catch (e) {
        customData = {};
      }
    }

    // Update the user count from signature_limit
    const signatureLimit = Number(customData?.signature_limit);
    return { isActive: true, signatureLimit };
  }

  return { isActive: false, signatureLimit: null };
};

const SubscriptionPlans = () => {
  const {
    state: { currentUser, recurringPlans, subscriptionDetails },
  } = useAppContext();
  const { workspaceId } = useWorkspaceId();

  const dialogRef = useRef(null);
  const userId = currentUser?.id;
  const [paddle, setPaddle] = useState(null);
  const [loader, setLoader] = useState(true);

  const [getRecurringPlans] = useGetRecurringPlans();
  const [getSubscriptionDetails] = useSubscriptionDetails();
  const [getPaddleCheckoutTransactionId] = useGetPaddleCheckoutTransactionId();
  const [updatePaddleSubscriptionPlan] = useUpdatePaddleSubscriptionPlan();
  const { initializePaddleClient } = useInitializePaddleClient();
  // Fetch the recurring plans and subscription details

  const plans = recurringPlans.filter((plan) =>
    ['SOLO', 'TEAMS'].includes(plan.name),
  );

  const fetchPlansData = async () => {
    setLoader(true);
    await getSubscriptionDetails({
      variables: {
        where: { userId },
      },
    });
    await getRecurringPlans();
    setLoader(false);
  };

  useEffect(() => {
    initializePaddleClient(setPaddle); // Initialize Paddle for checkout
  }, []);

  useEffect(() => {
    fetchPlansData();
  }, []);

  const { isActive, signatureLimit } = handleActivePlan(
    subscriptionDetails,
    plans,
  );

  // Handle opening the checkout process or updating the subscription plan
  const openCheckout = async (priceId, planId) => {
    // Check if the plan is already active
    if (isActive) {
      setLoader(true);
      const { data: updatedPlan } = await updatePaddleSubscriptionPlan({
        variables: { where: { priceId, planId } }, // No need to pass userId
      });

      if (updatedPlan?.updatePaddleSubscriptionPlan?.success) {
        await getSubscriptionDetails({
          variables: {
            where: { userId },
          },
        });
        window.location.href = `/app/${workspaceId}/user/billing`;
      }
      setLoader(false);
      return;
    }

    // If not active, proceed with Paddle Checkout
    setLoader(true);
    const res = await getPaddleCheckoutTransactionId({
      variables: { where: { priceId } },
    });
    const transactionId =
      res?.data?.getpaddleCheckoutTransactionId?.transactionId;

    if (paddle && transactionId) {
      paddle.Checkout.open({
        transactionId,
        // settings: {
        //   successUrl: `${window.location.origin}/app/user/billing`,
        // },
        customData: { userId, planId },
      });
    }
    setLoader(false);
  };

  if (loader) {
    return (
      <LoaderComponent
        circleClassName="mt-20 w-7 h-7 text-gray-500"
        setWidth="100%"
        classNames="w-full h-full relative"
      />
    );
  }

  const isPlanDisabled = () => {};

  return (
    <div className="flex flex-col items-center">
      <Tabs
        defaultValue={currentUser?.planId !== 7 ? 'solo' : 'teams'}
        className="w-fit"
      >
        <TabsList className="bg-primary-foreground px-1 py-5 rounded-full mb-4 flex justify-center">
          <TabsTrigger
            value="solo"
            className={cn(
              'rounded-full flex items-center justify-center border-0 p-4 bg-[transparent] cursor-pointer',
              'data-[state=active]:bg-primary data-[state=active]:text-white-0',
            )}
          >
            Personal
          </TabsTrigger>{' '}
          <TabsTrigger
            value="teams"
            className={cn(
              'rounded-full flex items-center justify-center border-0 p-4 bg-[transparent] cursor-pointer',
              'data-[state=active]:bg-primary data-[state=active]:text-white-0',
            )}
          >
            Business
          </TabsTrigger>
        </TabsList>

        <TabsContent value="solo">
          <SoloPlans
            plans={plans}
            subscriptionDetails={subscriptionDetails}
            openCheckout={openCheckout}
          />
        </TabsContent>

        <TabsContent value="teams">
          <TeamPlans
            plans={plans}
            subscriptionDetails={subscriptionDetails}
            dialogRef={dialogRef}
            openCheckout={openCheckout}
          />
        </TabsContent>
      </Tabs>

      <div className="mt-4">
        <Button onClick={handleContactUs} className="" variant="link">
          If you have any questions, Contact Us
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionPlans;
