import React, { forwardRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';

const CustomEditor = forwardRef(({ value, onBlur, onChange }, ref) => {
  const config = useMemo(
    () => ({
      toolbar: false,
      readonly: false,
      showPlaceholder: true,
      buttons: [],
      statusbar: false,
      removeButtons: ['about', 'source', 'iframe'],
      askBeforePasteHTML: false, // Disable "Paste as HTML" prompt
      askBeforePasteFromWord: false, // Disable "Paste from Word" prompt
      defaultActionOnPaste: 'insert_only_text', // Insert only text by default on paste
      minHeight: '50px', // Minimum height
      height: 'auto',
      style: {
        // borderRadius: '0px', // Set border radius
        // border: '1px solid #ccc', // Set border color
        resize: 'vertical', // Allow vertical resizing
        overflow: 'auto', // Ensure the content is scrollable when resized
      },
    }),
    [],
  );

  return (
    <JoditEditor
      ref={ref}
      value={value}
      config={config}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
});

export default CustomEditor;
