import React from 'react';
import { get } from 'lodash';
import { getFieldData, getLiveUrl } from 'common/utils';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Phone from './components/Phone';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';

function TemplateStyleEight({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl =
    signature?.headshot?.config?.output ??
    `${getLiveUrl(signature?.headshot?.config?.output)}`;
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef } = useTableWidth(downloadUrl);
  const { fields, design } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    !getFieldData(fields, 'headshotUrl') &&
    !getFieldData(fields, 'name') &&
    !getFieldData(fields, 'position') &&
    !getFieldData(fields, 'department') &&
    !getFieldData(fields, 'companyName');

  const section3 =
    !getFieldData(fields, 'phone') &&
    !getFieldData(fields, 'email') &&
    !getFieldData(fields, 'companyAddress') &&
    !getFieldData(fields, 'website');

  const section5 = socialFields?.every((obj) => obj?.value === '');

  return (
    <table
      style={{
        margin: '0 !important',
        padding: '0 !important',
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td>
            <table cellPadding={0}>
              <tbody>
                <tr>
                  <table
                    ref={mainContentRef}
                    align="center"
                    cellPadding="0"
                    cellSpacing="0"
                    border="0"
                    role="presentation"
                  >
                    {!section1 && (
                      <tr>
                        <td align="center">
                          <table cellPadding={0}>
                            {getFieldData(fields, 'headshotUrl') && (
                              <tr align="center">
                                <HeadshotImage
                                  data={getFieldData(fields, 'headshotUrl')}
                                  design={design}
                                />
                              </tr>
                            )}
                            {getFieldData(fields, 'name') && (
                              <tr>
                                {Name({
                                  signature,
                                  data: getFieldData(fields, 'name'),
                                  style: {
                                    fontWeight: 700,
                                    color: design?.primaryBrandColor,
                                    textAlign: 'center',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'companyName') && (
                              <tr>
                                {CompanyName({
                                  signature,
                                  data: getFieldData(fields, 'companyName'),
                                  style: {
                                    textAlign: 'center',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'position') && (
                              <tr>
                                {Position({
                                  signature,
                                  data: getFieldData(fields, 'position'),
                                  style: {
                                    paddingRight: '8px',
                                    textAlign: 'center',
                                  },
                                })}
                              </tr>
                            )}
                            {getFieldData(fields, 'department') && (
                              <tr>
                                {Department({
                                  signature,
                                  data: getFieldData(fields, 'department'),
                                  style: {
                                    textAlign: 'center',
                                  },
                                })}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}
                    {!(section1 || section3) && (
                      <tr>
                        <Divider
                          signature={signature}
                          style={{ marginTop: 8, marginBottom: 8 }}
                        />
                      </tr>
                    )}
                    {!section3 && (
                      <tr>
                        <td align="center">
                          <table
                            cellPadding="0"
                            cellSpacing="0"
                            border="0"
                            role="presentation"
                            width="max-content"
                            style={{
                              borderCollapse: 'collapse !important',
                              fontSize: 'inherit',
                            }}
                          >
                            {(getFieldData(fields, 'phone') ||
                              getFieldData(fields, 'email')) && (
                              <tr>
                                {getFieldData(fields, 'phone') &&
                                  Phone({
                                    signature,
                                    showAwsIcons,
                                    design,
                                    data: getFieldData(fields, 'phone'),
                                    style: {
                                      paddingRight: '6px',
                                    },
                                  })}
                                {getFieldData(fields, 'email') &&
                                  Email({
                                    signature,
                                    showAwsIcons,
                                    design,
                                    data: getFieldData(fields, 'email'),
                                  })}
                              </tr>
                            )}
                            {(getFieldData(fields, 'website') ||
                              getFieldData(fields, 'companyAddress')) && (
                              <tr>
                                {getFieldData(fields, 'website') &&
                                  Website({
                                    signature,
                                    showAwsIcons,
                                    design,
                                    data: getFieldData(fields, 'website'),
                                    style: {
                                      paddingRight: '6px',
                                    },
                                  })}
                                {getFieldData(fields, 'companyAddress') &&
                                  CompanyAddress({
                                    signature,
                                    showAwsIcons,
                                    design,
                                    data: getFieldData(
                                      fields,
                                      'companyAddress',
                                    ),
                                  })}
                              </tr>
                            )}
                          </table>
                        </td>
                      </tr>
                    )}
                    {!(section5 || section3) && (
                      <tr>
                        <Divider
                          signature={signature}
                          style={{ marginTop: 8, marginBottom: 8 }}
                        />
                      </tr>
                    )}
                    {!!socialFields?.length && (
                      <tr>
                        <td align="center">
                          <table cellPadding="0">
                            <tr>
                              {SocialLinks({
                                signature,
                                showAwsIcons,
                                data: socialFields,
                                design,
                                style: {
                                  paddingTop: '8px',
                                  paddingBottom: '8px',
                                },
                              })}
                            </tr>
                          </table>
                        </td>
                      </tr>
                    )}
                    {getFieldData(fields, 'companyLogo') && (
                      <tr align="center">
                        {CompanyLogo({
                          data: getFieldData(fields, 'companyLogo'),
                          design,
                          style: {
                            paddingBottom: '8px',
                          },
                          toProps: { colSpan: 2 },
                        })}
                      </tr>
                    )}

                    {getFieldData(fields, 'ctaUrl') &&
                      getFieldData(fields, 'ctaTitle') && (
                        <tr align="center">
                          {CallToAction({
                            signature,
                            showAwsIcons,
                            design,
                            data: {
                              ctaUrl: getFieldData(fields, 'ctaUrl'),
                              ctaTitle: getFieldData(fields, 'ctaTitle'),
                            },
                            style: { paddingBottom: '8px' },
                            tableprops: { align: 'center' },
                          })}
                        </tr>
                      )}
                    {getFieldData(fields, 'ctaBannerImg') &&
                      getFieldData(fields, 'ctaBannerUrl') && (
                        <tr>
                          {CallToActionBanner({
                            data: {
                              ctaBannerUrl: getFieldData(
                                fields,
                                'ctaBannerUrl',
                              ),
                              ctaBannerImg: getFieldData(
                                fields,
                                'ctaBannerImg',
                              ),
                            },
                            design,
                            style: { textAlign: 'left', paddingTop: '8px' },
                          })}
                        </tr>
                      )}
                  </table>
                </tr>

                {getFieldData(fields, 'footer') && (
                  <tr>
                    <table>
                      <tr>
                        {Footer({
                          data: getFieldData(fields, 'footer'),
                          design,
                          style: {
                            color: '#7A7289',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: '12px',
                          },
                        })}
                      </tr>
                    </table>
                  </tr>
                )}
              </tbody>
            </table>
          </td>
        </tr>

        <Branding hideBranding={hideBranding} />
      </tbody>
    </table>
  );
}

export default TemplateStyleEight;
