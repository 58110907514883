/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { FONT_FAMILY, SOCIAL_LINKS } from 'common/constants';
import { useTableWidth } from 'hooks/table';
import { getFieldData, getLiveUrl } from 'common/utils';
import { get } from 'lodash';
import SocialLinks from './components/SocialLinks';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';
import HeadshotImage from './components/HeadshotImage';
import Name from './components/Name';
import Position from './components/Position';
import Department from './components/Department';
import CompanyName from './components/CompanyName';
import Email from './components/Email';
import Website from './components/Website';
import CompanyAddress from './components/CompanyAddress';
import CompanyLogo from './components/CompanyLogo';
import Branding from './components/Branding';
import Phone from './components/Phone';
import Divider from './components/Divider';
import CallToActionBanner from './components/CallToActionBanner';
import SocialBanners from './components/SocialBanners';
import SignOff from './components/SignOff';

function TemplateStyleTwentyOne({
  signature = {},
  showAwsIcons = false,
  hideBranding = false,
}) {
  const font = get(signature, 'design.font', FONT_FAMILY);
  const previousDownloadUrl = signature?.headshot?.config?.output
    ? `${getLiveUrl(signature?.headshot?.config?.output)}`
    : '';
  const downloadUrl = signature?.headshotUrl || previousDownloadUrl || '';
  const headshot = signature?.headshot;
  const { tableWidth, mainContentRef, extraContentRef } =
    useTableWidth(downloadUrl);
  const { design, fields } = signature || {};

  const socialFields = fields?.filter((field) =>
    [...SOCIAL_LINKS.map((s) => s.name)].includes(field.name),
  );

  const section1 =
    socialFields?.every((obj) => obj?.value === '') &&
    !getFieldData(fields, 'companyLogo');

  return (
    <table
      // width={tableWidth}
      cellPadding="0"
      cellSpacing="0"
      border="0"
      style={{
        fontFamily: font,
        userSelect: 'none',
      }}
    >
      <tbody>
        <tr>
          <td aria-label="main-content">
            <table
              cellPadding="0"
              cellSpacing="0"
              border="0"
              style={{
                borderCollapse: 'collapse',
                fontSize: 'inherit',
                marginBottom: '8px',
              }}
            >
              <tbody>
                <tr>
                  <td>
                    <table
                      cellPadding="0px"
                      cellSpacing="0"
                      border="0"
                      ref={mainContentRef}
                    >
                      <tbody>
                        <tr>
                          <td>
                            {getFieldData(fields, 'headshotUrl') && (
                              <table
                                cellPadding="0"
                                cellSpacing="0"
                                style={{
                                  paddingRight: '14px',
                                }}
                              >
                                <tbody>
                                  <tr align="center">
                                    {getFieldData(fields, 'headshotUrl') &&
                                      HeadshotImage({
                                        data: getFieldData(
                                          fields,
                                          'headshotUrl',
                                        ),
                                        design,
                                        style: {
                                          paddingBottom: '14px',
                                          paddingTop: '9px',
                                        },
                                      })}
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </td>
                          <td>
                            <table
                              cellPadding="0px"
                              cellSpacing="0"
                              border="0"
                              style={{
                                borderCollapse: 'collapse',
                              }}
                            >
                              <tbody>
                                <tr>
                                  <td>
                                    <table
                                      cellPadding="0"
                                      cellSpacing="0"
                                      border="0"
                                      style={{
                                        paddingBottom: '8px',
                                      }}
                                    >
                                      <tbody>
                                        {(getFieldData(fields, 'name') ||
                                          getFieldData(fields, 'position')) && (
                                          <tr>
                                            <td>
                                              <table
                                                cellPadding="0"
                                                cellSpacing="0"
                                                border="0"
                                                style={{
                                                  borderCollapse:
                                                    'collapse !important',
                                                  fontSize: 'inherit',
                                                }}
                                              >
                                                <tbody>
                                                  <tr>
                                                    {getFieldData(
                                                      fields,
                                                      'name',
                                                    ) &&
                                                      Name({
                                                        signature,
                                                        data: getFieldData(
                                                          fields,
                                                          'name',
                                                        ),
                                                        style: {
                                                          fontWeight: 700,
                                                          fontFamily: 'inherit',
                                                          textTransform:
                                                            'uppercase',
                                                        },
                                                      })}

                                                    {getFieldData(
                                                      fields,
                                                      'name',
                                                    ) &&
                                                      getFieldData(
                                                        fields,
                                                        'position',
                                                      ) && (
                                                        <td>
                                                          <span
                                                            style={{
                                                              padding: '0 8px',
                                                              color: 'black',
                                                            }}
                                                          >
                                                            {' '}
                                                            -{' '}
                                                          </span>
                                                        </td>
                                                      )}

                                                    {getFieldData(
                                                      fields,
                                                      'position',
                                                    ) &&
                                                      Position({
                                                        signature,
                                                        data: getFieldData(
                                                          fields,
                                                          'position',
                                                        ),
                                                        style: {
                                                          fontFamily: 'inherit',
                                                        },
                                                      })}
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </td>
                                          </tr>
                                        )}

                                        {getFieldData(fields, 'department') && (
                                          <tr valign="center">
                                            {Department({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'department',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                              },
                                            })}
                                          </tr>
                                        )}

                                        {getFieldData(
                                          fields,
                                          'companyName',
                                        ) && (
                                          <tr>
                                            {CompanyName({
                                              signature,
                                              data: getFieldData(
                                                fields,
                                                'companyName',
                                              ),
                                              style: {
                                                fontFamily: 'inherit',
                                              },
                                            })}
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </td>
                                </tr>
                                {getFieldData(fields, 'phone') && (
                                  <tr>
                                    {Phone({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'phone'),
                                      style: {},
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'email') && (
                                  <tr>
                                    {Email({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'email'),
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'website') && (
                                  <tr>
                                    {Website({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(fields, 'website'),
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'companyAddress') && (
                                  <tr>
                                    {CompanyAddress({
                                      signature,
                                      showAwsIcons,
                                      design,
                                      data: getFieldData(
                                        fields,
                                        'companyAddress',
                                      ),
                                      style: { paddingBottom: '2px' },
                                    })}
                                  </tr>
                                )}

                                {getFieldData(fields, 'ctaUrl') &&
                                  getFieldData(fields, 'ctaTitle') && (
                                    <tr>
                                      {CallToAction({
                                        signature,
                                        showAwsIcons,
                                        design,
                                        data: {
                                          ctaUrl: getFieldData(
                                            fields,
                                            'ctaUrl',
                                          ),
                                          ctaTitle: getFieldData(
                                            fields,
                                            'ctaTitle',
                                          ),
                                        },
                                        style: {
                                          paddingBottom: '8px',
                                        },
                                      })}
                                    </tr>
                                  )}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <Divider signature={signature} />
                </tr>
                {!section1 && (
                  <tr>
                    <td>
                      <table
                        width={tableWidth}
                        cellPadding="0"
                        cellSpacing=""
                        border="0"
                        role="presentation"
                        style={{
                          borderCollapse: 'collapse !important',
                          fontSize: 'inherit',
                        }}
                      >
                        <tr>
                          {getFieldData(fields, 'companyLogo') &&
                            CompanyLogo({
                              data: getFieldData(fields, 'companyLogo'),
                              design,
                              style: {
                                paddingBottom: '8px',
                              },
                              toProps: { colSpan: 2, align: 'left' },
                            })}

                          {!!socialFields?.length &&
                            SocialLinks({
                              signature,
                              showAwsIcons,
                              data: socialFields,
                              design,
                              style: {
                                paddingLeft: '8px',
                              },
                              tdProps: {
                                align: 'right',
                                valign: 'center',
                              },
                            })}
                        </tr>
                      </table>
                    </td>
                  </tr>
                )}

                <tr>
                  <td>
                    <table
                      width={tableWidth}
                      cellPadding="0"
                      cellSpacing=""
                      border="0"
                      role="presentation"
                      style={{
                        borderCollapse: 'collapse !important',
                        fontSize: 'inherit',
                      }}
                    >
                      <tr>
                        {getFieldData(fields, 'ctaBannerImg') &&
                          getFieldData(fields, 'ctaBannerUrl') &&
                          CallToActionBanner({
                            data: {
                              ctaBannerUrl: getFieldData(
                                fields,
                                'ctaBannerUrl',
                              ),
                              ctaBannerImg: getFieldData(
                                fields,
                                'ctaBannerImg',
                              ),
                            },
                            design,
                            style: { textAlign: 'left' },
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td>
                    <table
                      width={tableWidth}
                      cellPadding="0"
                      cellSpacing=""
                      border="0"
                      role="presentation"
                      style={{
                        borderCollapse: 'collapse !important',
                        fontSize: 'inherit',
                      }}
                    >
                      <tr>
                        {getFieldData(fields, 'footer') &&
                          Footer({
                            data: getFieldData(fields, 'footer'),
                            design,
                          })}
                      </tr>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
        <tr>
          <Branding hideBranding={hideBranding} />
        </tr>
      </tbody>
    </table>
  );
}

export default TemplateStyleTwentyOne;
