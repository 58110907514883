import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from 'components/ui/button';
import {
  Dialog as BaseDialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogOverlay,
} from 'components/ui/dialog';

const AlertDialog = forwardRef(({ handleOk, message, title }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
    close: () => {
      setIsOpen(false);
    },
  }));

  return (
    <BaseDialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogOverlay className="bg-white-0" />
      <DialogContent className="rounded-[6px] max-w-md border" hideCloseButto>
        <DialogHeader>
          <DialogTitle className="text-left m-0">
            {title || 'Are you absolutely sure'}
          </DialogTitle>
          <DialogDescription className="text-left">
            {message || 'This action will overwrite your current template data. Do you still want to continue?'}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <div className="flex justify-end gap-3 pt-4">
            <Button
              className="w-[max-content]"
              onClick={() => setIsOpen(false)}
              variant="outline"
            >
              Cancel
            </Button>
            <Button onClick={() => handleOk()}>Continue</Button>
          </div>
        </DialogFooter>
      </DialogContent>
    </BaseDialog>
  );
});

export default AlertDialog;
