/* eslint-disable no-undef */
import React, { createContext, useContext, useReducer, useRef } from 'react';
import client from './apollo';
import api from './common/api';
import {
  DISPLAY_MODE,
  PRIMARY_COLOR,
  REFRESH_TOKEN,
  TOKEN,
  USER,
  FONT_FAMILY,
  DEFAULT_DESIGN_STYLE,
} from './common/constants';
import { useRedirect } from './hooks/auth';

const getLoggedInUser = () => {
  // eslint-disable-next-line no-undef
  let loggedInUser = localStorage?.getItem(USER);
  loggedInUser = loggedInUser ? JSON?.parse(loggedInUser) : null;

  return loggedInUser;
};

const initialState = {
  currentUser: getLoggedInUser() || {},
  // eslint-disable-next-line no-undef
  authToken: localStorage?.getItem(TOKEN),
  showPrompt: false,
  disablePublishButton: false,
  signature: {
    fields: [],
    information: {
      personalInformation: {},
      contactInformation: {},
      businessInformation: {},
      socialLinks: {},
      shortSocialLinks: {},
      footer: '',
    },
    cta: {
      title: '',
      url: '',
      shortUrl: '',
    },

    design: {
      primaryBrandColor: PRIMARY_COLOR,
      font: FONT_FAMILY,
      theme: DISPLAY_MODE?.LIGHT,
      icons: [],
      styles: DEFAULT_DESIGN_STYLE,
    },
    templateId: null,
    template: null,
    headshotId: null,
  },
  signatureIconsUploading: false,
  headshot: {
    id: '',
    image: '',
    style: {},
    styleId: '',
    config: {
      'background-color': PRIMARY_COLOR,
      grayScale: null,
      output: null,
      processedImageKey: null,
    },
  },
  downloadUrl: '',
  loading: false,
  isHeadshotUpdated: false,
  toggleSidebar: false,
  isTablet: false,
  screenAccess: true,
  analyticsData: {},
  isPublicOnboard: false,
  isLogin: false,
  userLocalData: getLoggedInUser() || {},
  showSubscriptionModal: false,
  plans: [],
  baseImages: [],
  signatureWidth: null,
  signaturecta: null,
  aiHeadshot: null,
  aiHeadshotLoading: false,
  step: 1,
  showAwsIcons: false,
  showBranding: false,
  owner: null, // New owner state
  subscriptionDetails: [],
  recurringPlans: [],
  templates: [],
};

const reducer = (state, action) => {
  switch (action?.type) {
    case 'SET_CURRENT_USER':
      // eslint-disable-next-line no-case-declarations
      const user = action?.data || {};

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        user && Object?.keys(user)?.length ? JSON?.stringify(user) : null,
      );
      return { ...state, currentUser: { ...user } };
    case 'SET_OWNER':
      return { ...state, owner: action?.data };
    case 'SET_CURRENT_ROLE':
      return { ...state, currentRole: action?.data };
    case 'SET_SHOW_PROMPT':
      return { ...state, showPrompt: action?.data };
    case 'LOGOUT':
      delete api?.defaults?.headers?.common?.Authorization;
      // eslint-disable-next-line no-undef
      localStorage?.clear();
      client?.clearStore();
      return {
        ...initialState,
        isLogin: false,
        authToken: null,
        user: {},
      };
    case 'SET_FETCHING_USER_DETAILS':
      return { ...state, fetchingUserDetails: action?.data };
    case 'SET_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(TOKEN, action?.data);
      return { ...state, authToken: action?.data };
    case 'SET_REFRESH_TOKEN':
      // eslint-disable-next-line no-undef
      localStorage.setItem(REFRESH_TOKEN, action?.data);
      return {
        ...state,
        refreshToken: action?.data,
      };
    case 'SET_INITIAL_SHOW_ALL_FIELDS_STATUS':
      return { ...state, initialShowAllFieldStatus: action?.data };
    case 'SET_SIGNATURE':
      return { ...state, signature: action?.data };
    case 'SET_AI_HEADSHOT':
      return { ...state, aiHeadshot: action?.data };
    case 'SET_DISPLAY_MODE':
      return { ...state, displayMode: action?.data };
    case 'SET_CTA':
      return { ...state, cta: action?.data };
    case 'SET_DESIGN':
      return { ...state, design: action?.data };
    case 'SET_HEADSHOT_CONFIG':
      return { ...state, headshot: action?.data };
    case 'SET_HEADSHOT':
      return { ...state, headshot: action?.data };
    case 'SET_DOWNLOAD_URL':
      return { ...state, downloadUrl: action?.data };
    case 'SET_LOADING':
      return { ...state, loading: action?.data };
    case 'SET_AI_HEADSHOT_LOADING':
      return { ...state, aiHeadshotLoading: action?.data };
    case 'IS_HEADSHOT_UPDATED':
      return { ...state, isHeadshotUpdated: action?.data };
    case 'TOGGLE_SIDEBAR':
      return { ...state, toggleSidebar: action?.data };
    case 'IS_TABLET':
      return { ...state, isTablet: action?.data };
    case 'SET_SCREEN_ACCESS':
      return { ...state, screenAccess: action?.data };
    case 'SET_ANALYTICS_DATA':
      return { ...state, analyticsData: action?.data };
    case 'SET_IS_LOGIN':
      return { ...state, isLogin: action?.data };
    case 'SET_SIGNATURE_WIDTH':
      return { ...state, signatureWidth: action?.data };
    case 'SET_SIGNATURECTA_WIDTH':
      return { ...state, signaturecta: action?.data };
    case 'SET_USER_LOCAL_DATA':
      // eslint-disable-next-line no-case-declarations
      const userData = action?.data || {};

      // eslint-disable-next-line no-undef
      localStorage.setItem(
        USER,
        userData && Object?.keys(userData)?.length
          ? JSON?.stringify(userData)
          : null,
      );
      return { ...state, userLocalData: { ...userData } };
    case 'SET_SUBSCRIPTION_MODAL_STATE':
      return { ...state, showSubscriptionModal: action?.data };
    case 'SET_PLANS':
      return { ...state, plans: action?.data };
    case 'SET_SUBSCRIPTION_DETAILS':
      return { ...state, subscriptionDetails: action?.data };
    case 'SET_RECURRING_PLANS':
      return { ...state, recurringPlans: action?.data };
    case 'SET_STEP':
      return { ...state, step: action?.data };
    case 'SET_SIGNATURE_ICONS_UPLOADING':
      return { ...state, signatureIconsUploading: action?.data };
    case 'SET_SHOW_AWS_ICONS':
      return { ...state, showAwsIcons: action?.data };
    case 'SET_TEMPLATES':
      return { ...state, templates: action?.data };
    case 'SET_SHOW_BRANDING':
      return { ...state, showBranding: action?.data };
    case 'SET_BASE_IMAGES': {
      const baseImagesClone = [...state.baseImages];
      const index = baseImagesClone?.findIndex(
        (image) => image.key === action?.data?.key,
      );
      if (index !== -1) {
        baseImagesClone[index] = action?.data;
      } else {
        baseImagesClone.push(action?.data);
      }
      return { ...state, baseImages: baseImagesClone };
    }
    default:
      return { ...state };
  }
};

const AppContext = createContext({
  state: initialState,
  dispatch: () => {},
});

function AppContextProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const formRef = useRef();
  const { redirectUser } = useRedirect();
  const getToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(TOKEN) || null;
  const getRefreshToken = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(REFRESH_TOKEN);
  const getCurrentUser = () =>
    // eslint-disable-next-line no-undef
    localStorage?.getItem(USER)
      ? // eslint-disable-next-line no-undef
        JSON?.parse(localStorage?.getItem(USER))
      : {};
  const getCurrentUserRole = () => {
    const loggedInUser = getLoggedInUser();
    return loggedInUser?.roles?.[0] || '';
  };

  const disablePublishAction = (data) => {
    dispatch({ type: 'DISABLE_PUBLISH_ACTION', data });
  };

  const initializeAuth = (authToken, userData, refreshToken, configs = {}) => {
    const token = authToken || getToken();
    const user = userData || getCurrentUser();
    const refresh = refreshToken || getRefreshToken();
    if (token) {
      api.defaults.headers.common.Authorization = `Bearer ${token}`;
      dispatch({ type: 'SET_TOKEN', data: token });
      dispatch({ type: 'SET_REFRESH_TOKEN', data: refresh });
      dispatch({ type: 'SET_CURRENT_USER', data: user });
      if (user?.roles) {
        dispatch({ type: 'SET_CURRENT_ROLE', data: user?.roles });
      }
      redirectUser(configs);
    }
  };

  const initializeSocialAuth = (socialAuthLink) => {
    const token = socialAuthLink || getToken();
    if (token) {
      api.defaults.headers.common.Authorization = token;
      dispatch({ type: 'SET_TOKEN', data: token });
    }
  };

  const setOwner = (data) => {
    dispatch({ type: 'SET_OWNER', data });
  };
  const setSignature = (data) => {
    dispatch({ type: 'SET_SIGNATURE', data });
  };

  const setDisplayMode = (data) => {
    dispatch({ type: 'SET_DISPLAY_MODE', data });
  };

  const setCTA = (data) => {
    dispatch({ type: 'SET_CTA', data });
  };

  const setDesign = (data) => {
    dispatch({ type: 'SET_DESIGN', data });
  };

  const setHeadshot = (data) => {
    dispatch({ type: 'SET_HEADSHOT_CONFIG', data });
  };

  const setDownloadUrl = (data) => {
    dispatch({ type: 'SET_DOWNLOAD_URL', data });
  };

  const setLoading = (data) => {
    dispatch({ type: 'SET_LOADING', data });
  };
  const setAiHeadshotLoading = (data) => {
    dispatch({ type: 'SET_AI_HEADSHOT_LOADING', data });
  };

  const setHeadshotUpdateStatus = (data) => {
    dispatch({ type: 'IS_HEADSHOT_UPDATED', data });
  };

  const setToggleSidebar = (data) => {
    dispatch({ type: 'TOGGLE_SIDEBAR', data });
  };

  const setIsTablet = (data) => {
    dispatch({ type: 'IS_TABLET', data });
  };

  const setScreenAccess = (data) => {
    dispatch({ type: 'SET_SCREEN_ACCESS', data });
  };

  const setAnalyticsData = (data) => {
    dispatch({ type: 'SET_ANALYTICS_DATA', data });
  };

  const setIsLogin = (data) => {
    dispatch({ type: 'SET_IS_LOGIN', data });
  };
  const setSignatureWidth = (data) => {
    dispatch({ type: 'SET_SIGNATURE_WIDTH', data });
  };
  const setSignatureCTAWidth = (data) => {
    dispatch({ type: 'SET_SIGNATURECTA_WIDTH', data });
  };
  const setUserLocalData = (data) => {
    dispatch({ type: 'SET_USER_LOCAL_DATA', data });
  };
  const setCurrentUser = (data) => {
    dispatch({ type: 'SET_CURRENT_USER', data });
  };

  const handleSubscriptionModal = (data) => {
    dispatch({ type: 'SET_SUBSCRIPTION_MODAL_STATE', data });
  };

  const setPlans = (data) => {
    dispatch({ type: 'SET_PLANS', data });
  };

  const setSubscriptionDetails = (data) => {
    dispatch({ type: 'SET_SUBSCRIPTION_DETAILS', data });
  };

  const setRecurringPlans = (data) => {
    dispatch({ type: 'SET_RECURRING_PLANS', data });
  };

  const setStep = (data) => {
    dispatch({ type: 'SET_STEP', data });
  };

  const setSignatureIconsUploading = (data) => {
    dispatch({ type: 'SET_SIGNATURE_ICONS_UPLOADING', data });
  };

  const setshowAwsIcons = (data) => {
    dispatch({ type: 'SET_SHOW_AWS_ICONS', data });
  };

  const setBaseImages = (data) => {
    dispatch({ type: 'SET_BASE_IMAGES', data });
  };

  const setToken = (data) => {
    dispatch({ type: 'SET_TOKEN', data });
  };

  const setTemplates = (data) => {
    dispatch({ type: 'SET_TEMPLATES', data });
  };
  const setAiHeadshot = (data) => {
    dispatch({ type: 'SET_AI_HEADSHOT', data });
  };
  const setShowBranding = (data) => {
    dispatch({ type: 'SET_SHOW_BRANDING', data });
  };
  const value = {
    state,
    dispatch,
    getToken,
    getRefreshToken,
    initializeAuth,
    initializeSocialAuth,
    getCurrentUserRole,
    getCurrentUser,
    setOwner,
    disablePublishAction,
    setSignature,
    setDisplayMode,
    setCTA,
    setDesign,
    setHeadshot,
    setDownloadUrl,
    setLoading,
    setHeadshotUpdateStatus,
    setToggleSidebar,
    setIsTablet,
    setScreenAccess,
    setAnalyticsData,
    setIsLogin,
    setSignatureWidth,
    setSignatureCTAWidth,
    setUserLocalData,
    setCurrentUser,
    handleSubscriptionModal,
    setPlans,
    setSubscriptionDetails,
    setRecurringPlans,
    setSignatureIconsUploading,
    setBaseImages,
    setToken,
    setAiHeadshot,
    setAiHeadshotLoading,
    setStep,
    setshowAwsIcons,
    setShowBranding,
    formRef,
    setTemplates,
  };

  return <AppContext.Provider value={value}> {children}</AppContext.Provider>;
}

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useMyContext must be used within a MyContextProvider');
  }
  return context;
};

export { AppContext, AppContextProvider, useAppContext };
