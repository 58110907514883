import WithImageConversion from 'common/helpers/htmlToImage';
import { handleUrlRedirection } from 'common/utils';
import { get, isString, omit } from 'lodash';
import React from 'react';
import { getCtaCustomStyle } from '../../utils/constant_style';

const CtaTypeWrapper = ({
  type,
  styles,
  children,
  signature,
  showAwsIcons,
}) => {
  const updatedStyle = {
    colored: {
      ...styles,
      backgroundColor: styles?.primaryBrandColor,
      color: 'white',
      padding: '6px 12px',

      justifyContent: styles.justifyContent || 'center',
    },
    bordered: {
      ...styles,
      backgroundColor: 'transparent',
      border: `1px solid ${styles?.primaryBrandColor}`,
      color: styles?.primaryBrandColor,
      padding: '6px 12px',

      justifyContent: styles.justifyContent || 'center',
      verticalAlign: 'center',
    },
    text: {
      ...styles,
      backgroundColor: 'transparent',
      color: styles?.primaryBrandColor,
      padding: '6px 0px',
      textDecoration: 'underline',
      fontWeight: 700,
      justifyContent: styles.justifyContent || 'center',
    },
  };

  const content = (
    <span
      style={{
        ...omit(styles, ['fontSize']),
        ...(updatedStyle[type] || {}),
        display: 'flex',
        alignItems: 'center',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '16px',
        cursor: 'pointer',
        width: styles.width || 100,
        borderRadius: styles.borderRadius || 0,
      }}
    >
      {children}
    </span>
  );

  if (['colored', 'bordered'].includes(type)) {
    return (
      <WithImageConversion
        signature={signature}
        showAwsIcons={showAwsIcons}
        key="cta"
        fileName="cta"
      >
        {content}
      </WithImageConversion>
    );
  }
  return content;
};

function CallToAction({
  design,
  data,
  style = {},
  tdProps = {},
  signature = {},
  tableprops = {},
  showAwsIcons,
}) {
  const { styles } = get(signature, 'design', {});
  const {
    width = 100,
    borderRadius,
    type = 'colored',
  } = get(styles, 'cta.style', {});
  const { ctaTitle, ctaUrl } = data;

  const customStyle = {
    ...style,
    primaryBrandColor: design?.primaryBrandColor,
    width,
    borderRadius,
  };

  return (
    <td
      aria-label="cta"
      style={{
        ...style,
      }}
      {...tdProps}
    >
      <a
        aria-label="link"
        style={{
          color: 'transparent',
          textDecoration: 'none',
        }}
        href={handleUrlRedirection(ctaUrl?.value)}
        target="_blank"
        rel="noreferrer"
      >
        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
          <tbody>
            <tr {...tableprops}>
              <td>
                <CtaTypeWrapper
                  type={type}
                  styles={customStyle}
                  signature={signature}
                  showAwsIcons={showAwsIcons}
                >
                  {ctaTitle?.value}
                </CtaTypeWrapper>
              </td>
            </tr>
          </tbody>
        </table>
      </a>
    </td>
  );
}

export default CallToAction;
