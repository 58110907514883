import React, { useState } from 'react';
import { Button } from 'components/ui/button';
import { Gmail, Outlook, AppleMail, Yahoo } from 'assets/svg';
import InstallGuide from './InstallGuide';

const linkArray = [
  {
    icon: 'gmail', // We'll conditionally render this icon later
    label: 'Add to Gmail',
    contentId: 'gmail',
  },
  {
    icon: 'outlook',
    label: 'Add to Outlook',
    contentId: 'outlook',
  },
  {
    icon: 'appleMail',
    label: 'Add to Apple Mail',
    contentId: 'applemail',
  },
  {
    icon: 'yahoo',
    label: 'Add to Yahoo',
    contentId: 'yahoo',
  },
];

const SignatureLinkButtons = ({ showLabel = true }) => {
  const [dialogContent, setDialogContent] = useState('');
  const [openContentDialog, setOpenContentDialog] = useState(false);

  const handleLinkClick = (content) => {
    setDialogContent(content);
    setOpenContentDialog(true);
  };

  // Helper function to render icons with conditional sizes
  const renderIcon = (iconName) => {
    const size = showLabel ? 28 : 35;
    switch (iconName) {
      case 'gmail':
        return <Gmail height={size} width={size} className="mr-2" />;
      case 'outlook':
        return <Outlook height={size} width={size} className="mr-2" />;
      case 'appleMail':
        return <AppleMail height={size} width={size} className="mr-2" />;
      case 'yahoo':
        return <Yahoo height={size} width={size} className="mr-2" />;
      default:
        return null;
    }
  };

  return (
    <>
      <InstallGuide
        open={openContentDialog}
        closeModal={() => setOpenContentDialog(false)}
        content={dialogContent}
      />
      {showLabel ? (
        <div className="flex gap-x-[28px] w-full justify-center">
          {linkArray.map((link) => (
            <Button
              key={link.contentId}
              variant="outline"
              className="h-[54px] w-auto text-secondary-500"
              onClick={() => handleLinkClick(link.contentId)}
            >
              {renderIcon(link.icon)} {link.label}
            </Button>
          ))}
        </div>
      ) : (
        <div className="flex gap-x-[14px] w-full justify-center">
          {linkArray.map((link) => (
            <Button
              key={link.contentId}
              variant="text"
              className="h-[54px] w-auto text-secondary-500"
              onClick={() => handleLinkClick(link.contentId)}
            >
              {renderIcon(link.icon)} {/* Render icon without label */}
            </Button>
          ))}
        </div>
      )}
    </>
  );
};

export default SignatureLinkButtons;
